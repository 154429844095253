import Icon from '../Icon/Icon'

const IconButton = ({
	className,
	onClick,
	src,
	alt = '',
	size = 36,
	parentStyle = {},
	style = {},
	children,
	handlerErrorLoadImage,
}) => {
	return (
		<div
			className={className}
			onClick={onClick}
			onKeyDown={onClick}
			style={{ ...parentStyle, maxHeight: '100%', maxWidth: '100%' }}
			role='button'
			tabIndex={0}
			aria-label='icon'>
			<Icon
				src={src}
				alt={alt}
				size={size}
				style={style}
				handlerErrorLoadImage={handlerErrorLoadImage}
			/>
			{/* children must always be absolute, and the className to this container relative */}
			{children}
		</div>
	)
}

export default IconButton

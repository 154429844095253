import { useContext, useEffect, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import SaveButton from '../../SaveButton'
import { ObjectLevelLanguageContext } from './ObjectLevelLanguage'
import {
	onInputChangeDefault,
	processErrors,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { API_MANAGE_LEVELS_LANGUAGES } from '../../../../utils/constantsAdmin'
import { useTranslation } from 'react-i18next'
import { ManageStorageGameLanguage } from '../../../../utils/manage_local_storage'

export function FormLevelLanguage({ ObjectLevel, setRefreshLevels }) {
	const { t } = useTranslation()
	// LOCAL STORAGE
	const { getGameLanguage } = ManageStorageGameLanguage()
	const { axiosSupreme } = useAuthProvider()
	// CONTEXT OBJECT LANGUAGE
	const { setObjectLevelLanguage } = useContext(ObjectLevelLanguageContext)

	// ALERT, MODAL
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)
	const { openModal } = useContext(ModalContext)

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// VALUES FORM
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setDescription('')
	}

	// SET LEVEL WHEN UPDATE
	useEffect(() => {
		if (ObjectLevel !== undefined) {
			setName(ObjectLevel.name)
			setDescription(ObjectLevel.description)
		} else {
			setName('')
			setDescription('')
		}
	}, [ObjectLevel])

	// SUBMIT FORM LEVEL
	const onSubmitLevel = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		let idGameLanguage = JSON.parse(getGameLanguage())
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		formData.append('description', event.target.elements.description.value)
		formData.append('game_language', idGameLanguage.id_game_language)
		if (ObjectLevel === undefined) {
			// SAVE
			manageLevel(API_MANAGE_LEVELS_LANGUAGES, formData, 'add')
		} else {
			// UPDATE
			manageLevel(
				API_MANAGE_LEVELS_LANGUAGES +
					ObjectLevel.id_level_language +
					'/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE LEVEL
	const manageLevel = async (url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(t('success.action_success'))
			showAlert()
			if (action === 'add') {
				setRefreshLevels((prev) => !prev)
				setObjectLevelLanguage(undefined)
			}
			if (action === 'update') setObjectLevelLanguage(result_data)
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	return (
		<form onSubmit={onSubmitLevel}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_level_language.name_level'
					)}
				</label>
				<input
					maxLength='45'
					name='name'
					className='admin__container__inputs__in'
					id='name'
					type='text'
					placeholder={t(
						'pages.editor.components.form_level_language.name_placeholder'
					)}
					autoComplete='off'
					value={name}
					onChange={(event) => onInputChangeDefault(event, setName)}
					required></input>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_level_language.description'
					)}
				</label>
				<textarea
					maxLength='500'
					name='description'
					className='admin__container__inputs__in'
					id='description'
					type='text-area'
					placeholder={t(
						'pages.editor.components.form_level_language.description_placeholder'
					)}
					autoComplete='off'
					value={description}
					onChange={(event) =>
						onInputChangeDefault(event, setDescription)
					}
					required></textarea>
			</div>

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

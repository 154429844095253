import { useState, useRef, createContext, useContext } from 'react'

import {
	USER_API,
	API_PROFILE_CLOSE,
	URL_PROFILE_INITIAL,
	API_WORLD_SCENE,
	API_WORLD_SCENE_CLOSE,
	API_SUB_WORLD_SCENE,
	API_SUB_WORLD_SCENE_CLOSE,
	API_LEVEL_RESUME,
	API_LEVEL_RESUME_CLOSE,
	API_PROGRESS_BAR,
	API_PROGRESS_BAR_CLOSE,
} from '../utils/constants'

import { Scene } from './Scene'

import myCookies from '../utils/myCookies'
import { useAuthProvider } from '../context/AuthProvider/AuthProvider'
import { useLostItems } from '../context/LostItemsProvider'

import flying from '../assets/audios/TLSG11-TeoloroAlas.mp3'

// Nuevos audios para la interfaz
import TLIJ1 from '../assets/newAudiosForInterface/TLIJ1-JugadaCorrecta.mp3'
import TLIJ2 from '../assets/newAudiosForInterface/TLIJ2-Clicparaarrastrar,seleccionar.mp3'
import TLIJ3 from '../assets/newAudiosForInterface/TLIJ3-Clicdesoltarobjeto.mp3'
import TLIJ4 from '../assets/newAudiosForInterface/TLIJ4-VoltearTarjetaenJuego.mp3'
import TLIJ5 from '../assets/newAudiosForInterface/TLIJ5-GloboEstallando.mp3'
import TLIJ6 from '../assets/newAudiosForInterface/TLIJ6-Comenzar,VolveraIntentar.mp3'
import TLIJ7 from '../assets/newAudiosForInterface/TLIJ7-Finalizar.mp3'

import TLMJ1 from '../assets/newAudiosForInterface/TLMJ1-SoldeMediaNoche.mp3'

import TLME1 from '../assets/newAudiosForInterface/TLME1-1Estrella.mp3'
import TLME2 from '../assets/newAudiosForInterface/TLME2-2Estrella.mp3'
import TLME3 from '../assets/newAudiosForInterface/TLME3-3Estrellas.mp3'
import TLME4 from '../assets/newAudiosForInterface/TLME4-GameOver.mp3'

import TLSG1 from '../assets/newAudiosForInterface/TLSG1-AbrirVentana.mp3'
import TLSG2 from '../assets/newAudiosForInterface/TLSG2-CerrarVentana.mp3'
import TLSG3 from '../assets/newAudiosForInterface/TLSG3-UIClick.mp3'
import TLSG4 from '../assets/newAudiosForInterface/TLSG4-Zoomin.mp3'
import TLSG5 from '../assets/newAudiosForInterface/TLSG5-Zoomout.mp3'
import TLSG6 from '../assets/newAudiosForInterface/TLSG6-AbrirBotónPregunta.mp3'
import TLSG7 from '../assets/newAudiosForInterface/TLSG7-CerrarBotónPregunta.mp3'
import TLSG8 from '../assets/newAudiosForInterface/TLSG8-Siguiente,Anterior,Continuar.mp3'
import TLSG9 from '../assets/newAudiosForInterface/TLSG9-BotónOmitir.mp3'
import TLSG10 from '../assets/newAudiosForInterface/TLSG10-Skip.mp3'

import TLSI1 from '../assets/newAudiosForInterface/TLSI1-ComprarSkin.mp3'
import TLSI2 from '../assets/newAudiosForInterface/TLSI2-CambiodeSkin.mp3'
import TLSI3 from '../assets/newAudiosForInterface/TLSI3-Experiencia.mp3'
import TLSI4 from '../assets/newAudiosForInterface/TLSI4-Monedas.mp3'
import TLSI5 from '../assets/newAudiosForInterface/TLSI5-MonedasyExperiencia.mp3'
import TLSI6 from '../assets/newAudiosForInterface/TLSI6-HuevoZona.mp3'
import TLSI7 from '../assets/newAudiosForInterface/TLSI7-CogerHuevo.mp3'
import TLSI8 from '../assets/newAudiosForInterface/TLSI8-ObjetoPerdido.mp3'
import TLSI9 from '../assets/newAudiosForInterface/TLSI9-MisionesCompletadas.mp3'
import TLSI10 from '../assets/newAudiosForInterface/TLSI10-NivelCompletado.mp3'
import TLSI11 from '../assets/newAudiosForInterface/TLSI11-ObjetoPerdido-Encuentrame.mp3'
import TLSI12 from '../assets/newAudiosForInterface/TLSI12-Huevo-Encuentrame.mp3'
import { SceneProvider } from '../context/sceneContext'
import { detectarIOS } from '../utils/detectarIOS'
import { getRealUrl } from '../utils/getRealUrl'
import { useNavigate } from 'react-router-dom'

const updateProfileContext = createContext(null)

export function useUpdateProfileContext() {
	return useContext(updateProfileContext)
}

/**
 * Renders the scene (game) with the userData (where is he in the progress)
 * @param {Function} goToNextScene mean to be called when the user crosses a door
 * @returns {JSX} the scene
 */
const SceneDataFetch = ({
	goToNextScene,
	updateLoadingBarPercentage,
	loadingBarWasCompleted,
}) => {
	const { axiosSupreme, peticionesEnParalelo } = useAuthProvider()
	/**
	 * @type {Boolean} reload as the userData is a useRef, it doesn't reloads by its own when changed...
	 * @type {Boolean} dataWasSuccessfullyFetched if the necessary data for the new scene
	 * was successfully fetched, so it can procced to render without problem.
	 * @type {Object} userData contains all the info of the user.
	 * @type {Object} sceneData contains all the data of the scene (NPCs, doors, decorations, Map image/sounds)
	 */
	const [reload, setReload] = useState(false)
	const [dataWasSuccessfullyFetched, setDataWasSuccessfullyFetched] =
		useState(false)
	const userData = useRef(null)
	const profileData = useRef(null)
	const sceneData = useRef(null)
	const actualDoor = useRef(null)
	const levelResume = useRef(null)

	const apiTryAgainLimitAmount = 5

	const updateProfileDataCounter = useRef(0)

	async function updateProfileData() {
		try {
			profileData.current = await axiosSupreme(
				'get',
				URL_PROFILE_INITIAL,
				userData.current.data.id_profile + '/webp' + API_PROFILE_CLOSE
			)

			updateProfileDataCounter.current = 0
			setReload((r) => !r)
		} catch (error) {
			console.error(error)
			updateProfileDataCounter.current =
				updateProfileDataCounter.current + 1
			if (updateProfileDataCounter.current <= apiTryAgainLimitAmount) {
				updateProfileData()
			}
		}
	}

	/**
	 * Depending on the type of the next scene: scene it fetches its corresponding.
	 * Once the data has been fetched it procedes to render the scene. If the data fetch
	 * errors, the platform Component will remain invisible forever.
	 */
	// ------ ONCE
	const userCounter = useRef(0)
	const sceneCounter = useRef(0)
	const levelCounter = useRef(0)

	let fetchUser
	let fetchScene
	let fetchLevel

	const { updateLostItemsData } = useLostItems()
	const navigate = useNavigate()

	const once = useRef(false)
	if (!once.current) {
		once.current = true

		function handleError(counter, fetchFunction) {
			counter.current = counter.current + 1
			if (counter.current <= apiTryAgainLimitAmount) {
				const timeout = setTimeout(() => {
					clearTimeout(timeout)
					fetchFunction()
				}, 2000)
			} else {
				window.location.replace('/')
				// const timeout = setTimeout(() => {
				// 	clearTimeout(timeout)
				// 	window.location.reload()
				// }, 2000)
			}
		}

		/**
		 * Fetches the data in order function per function
		 */
		fetchUser = async () => {
			// ----- #1 GET USER
			try {
				userData.current = await axiosSupreme(
					'get',
					USER_API + myCookies.get.gameLanguageID() + '/',
					undefined
				)

				if (!userData.current) throw new Error('error')
				// ----- #2 GET THE PROFILE DATA
				await updateProfileData()

				setTimeout(() => {
					updateLostItemsData(profileData.current.id_profile, true)
				}, 1500)

				// ----- #3 GET THE AVAILABLE NEXT DOOR (WORLD OR SUB_WORLD)
				const door = profileData.current.world_door
					? profileData.current.world_door
					: profileData.current.sub_world_door
				const isWorld = profileData.current.world_door !== null
				actualDoor.current = {
					...door,
					isWorld: isWorld,
				}
				const sceneID = isWorld
					? actualDoor.current.world_scene
					: actualDoor.current.sub_world_scene

				// ----- #4 GET THE RIGHT API_SCENE (DEPENDING ON WORLD OR SUB_WORLD)
				const API_SCENE = profileData.current.world_door
					? API_WORLD_SCENE
					: API_SUB_WORLD_SCENE

				const API_SCENE_CLOSE = profileData.current.world_door
					? API_WORLD_SCENE_CLOSE
					: API_SUB_WORLD_SCENE_CLOSE

				// ----- #5 GET THE DATA OF THE NEW SCENE
				fetchScene(API_SCENE, API_SCENE_CLOSE, sceneID)
			} catch (error) {
				console.error(error)
				handleError(userCounter, fetchUser)
			}
		}

		fetchScene = async (API_SCENE, API_SCENE_CLOSE, sceneID) => {
			try {
				sceneData.current = await axiosSupreme(
					'get',
					API_SCENE,
					sceneID +
						API_SCENE_CLOSE +
						profileData.current.id_profile +
						'/webp/'
				)

				sceneData.current = formatSceneData()
				// ----- #6 GET THE RESUME OF THE LEVEL
				fetchLevel()
			} catch (error) {
				console.error(error)
				handleError(sceneCounter, fetchScene)
			}
		}

		fetchLevel = async () => {
			try {
				let levelResumeRequest
				if (sceneData.current.subWorld) {
					const objOfRequests = [
						{
							reqType: 'get',
							url:
								API_LEVEL_RESUME +
								profileData.current.id_profile +
								API_LEVEL_RESUME_CLOSE +
								sceneData.current.subWorld.id_sub_world +
								'/',
							data: {},
						},
						{
							reqType: 'get',
							url:
								API_PROGRESS_BAR +
								profileData.current.id_profile +
								API_PROGRESS_BAR_CLOSE,
							data: {},
						},
					]

					// ----- #6 GET THE INITIAL DATA OF THE PROGRESS BAR
					const arrOfResponses = await peticionesEnParalelo(
						objOfRequests
					)

					levelResumeRequest = arrOfResponses[0].data
					sceneData.current.progressBarInitData =
						arrOfResponses[1].data

					const isAvailable =
						sceneData.current.progressBarInitData.every(
							(dataMission) => dataMission.status === 'c'
						)

					sceneData.current.doors = sceneData.current.doors.map(
						(door) => {
							if (
								door.id === actualDoor.current.id_sub_world_door
							)
								return door

							door.isAvailable = isAvailable
							return door
						}
					)

					// const indexOfExitDoor = sceneData.current.doors.findIndex(
					// 	(door) => door.id === actualDoor.current.id_sub_world_door
					// )

					// if (indexOfExitDoor !== -1) {
					// 	console.log({ isAvailable });
					// 	sceneData.current.doors[indexOfExitDoor].isAvailable =
					// 		isAvailable
					// }
				}

				// ----- #6 RENDER THE SCENE
				setDataWasSuccessfullyFetched(true)

				levelResume.current = levelResumeRequest
			} catch (error) {
				console.error(error)
				handleError(levelCounter, fetchLevel)
			}
		}

		if (myCookies.get.gameLanguageID()) fetchUser()
		else navigate('/')
	}

	function formatSceneData() {
		function getAudioFormat(
			ID,
			file,
			isRoute = true,
			repeateWhilePlaying = false,
			isLoop = false
		) {
			return {
				ID,
				file: isRoute ? getRealUrl(file) : file,
				playBoolTrigger: true,
				pauseBoolTrigger: true,
				repeateWhilePlaying,
				isLoop,
			}
		}

		const isIOS = detectarIOS()
		let imageFile = sceneData.current.image_file

		if (sceneData.current?.resize?.exist_file && !isIOS) {
			imageFile = sceneData.current.resize.image_file
		}

		const [item] = profileData.current.categories_selected_items.filter(
			(category) => category.name === 'Skins'
		)[0].items

		let skinFile = item.image_file
		if (item?.resize?.exist_file) {
			skinFile = item.resize.image_file
		}

		const sceneDataFormated = {
			name: sceneData.current.name,
			imageFile,
			collisionFile: sceneData.current.collision_file,
			backgroundColor: sceneData.current.background_color,
			storyteller: sceneData.current.storyteller[0],
			subWorld: sceneData.current.sub_world,
			hasFinalScene: false,
			skinFile,
			doors: [],
			npcs: [],
			decorations: [],
			lostItems: [],
			/**
			 * The idea of this is that each sound object will contain a
			 * restartBoolTrigger, and a stopBoolTrigger.
			 *
			 * this sounds object will be cloned and transformed as a react state.
			 */
			levelEntranceDoorID: sceneData.current.level_entrance_door_id,
			sounds: {
				storyteller: {},
				// ID: sceneData.current.sound?.id_sound,
				// name: sceneData.current.sound?.name,
				background: sceneData.current.sound?.audio_file
					? getAudioFormat(null, sceneData.current.sound.audio_file)
					: undefined,
				spriteSheets: [],
				NPCS: {},
				games: {},
				items: {}, // skins
				effects: {
					flying: getAudioFormat(
						'flying',
						flying,
						false,
						false,
						true
					),

					//Estos audios estan soportados bajo la nomenclatura hecha por los músicos, se encuentra en el archivo
					//https://docs.google.com/document/d/1abTIagqUmryZ9Oub-rYDmlEcTXRNPcK2SGezDKq-kpU/edit
					//Si existe alguna duda sobre el acoplamiento de estos, consultar el link de arriba
					TLSG1: getAudioFormat('TLSG1', TLSG1, false, true),
					TLSG2: getAudioFormat('TLSG2', TLSG2, false, true),
					TLSG3: getAudioFormat('TLSG3', TLSG3, false, true),
					TLSG4: getAudioFormat('TLSG4', TLSG4, false, true),
					TLSG5: getAudioFormat('TLSG5', TLSG5, false, true),
					TLSG6: getAudioFormat('TLSG6', TLSG6, false, true),
					TLSG7: getAudioFormat('TLSG7', TLSG7, false, true),
					TLSG8: getAudioFormat('TLSG8', TLSG8, false, true),
					TLSG9: getAudioFormat('TLSG9', TLSG9, false, true),
					TLSG10: getAudioFormat('TLSG10', TLSG10, false, true),

					TLSI1: getAudioFormat('TLSI1', TLSI1, false, true),
					TLSI2: getAudioFormat('TLSI2', TLSI2, false, true),
					TLSI3: getAudioFormat('TLSI3', TLSI3, false, true),
					TLSI4: getAudioFormat('TLSI4', TLSI4, false, true),
					TLSI5: getAudioFormat('TLSI5', TLSI5, false, true),
					TLSI6: getAudioFormat('TLSI6', TLSI6, false, true, true),
					TLSI7: getAudioFormat('TLSI7', TLSI7, false, true),
					TLSI8: getAudioFormat('TLSI8', TLSI8, false, true),
					TLSI9: getAudioFormat('TLSI9', TLSI9, false, true),
					TLSI10: getAudioFormat('TLSI10', TLSI10, false, true),
					TLSI11: getAudioFormat('TLSI11', TLSI11, false, true),
					TLSI12: getAudioFormat('TLSI12', TLSI12, false, true),

					TLIJ1: getAudioFormat('TLIJ1', TLIJ1, false, true),
					TLIJ2: getAudioFormat('TLIJ2', TLIJ2, false, true),
					TLIJ3: getAudioFormat('TLIJ3', TLIJ3, false, true),
					TLIJ4: getAudioFormat('TLIJ4', TLIJ4, false, true),
					TLIJ5: getAudioFormat('TLIJ5', TLIJ5, false, true),
					TLIJ6: getAudioFormat('TLIJ6', TLIJ6, false, true),
					TLIJ7: getAudioFormat('TLIJ7', TLIJ7, false, true),

					TLME1: getAudioFormat('TLME1', TLME1, false, true),
					TLME2: getAudioFormat('TLME2', TLME2, false, true),
					TLME3: getAudioFormat('TLME3', TLME3, false, true),
					TLME4: getAudioFormat('TLME4', TLME4, false, true),

					TLMJ1: getAudioFormat('TLMJ1', TLMJ1, false, true),
					//---------------------------------------------------------------
				},
				ambientSound: sceneData.current.ambient_sound?.audio_file
					? getAudioFormat(
							null,
							sceneData.current.ambient_sound.audio_file
					  )
					: undefined,
			},
		}

		sceneData.current.storyteller[0]?.forEach((story) => {
			sceneDataFormated.sounds.storyteller[story.id_storyteller_content] =
				getAudioFormat(story.id_storyteller_content, story.audio_file)
		})

		// ----- $DOORS
		for (const data of sceneData.current.doors) {
			// ----- SELF MADE DOOR ANIMATIONS
			const animations = [
				{
					name: '-blocked',
					frames: { start: 0, end: 0 },
					duration: 1500,
					repeat: 0,
				},
				{
					name: '-available',
					frames: { start: 1, end: 1 },
					duration: 1500,
					repeat: 0,
				},
			]

			let doorFile = data.door_file
			if (data?.resize?.exist_file && !isIOS) {
				doorFile = data?.resize?.door_file
			}

			// ----- SPRITE SHEETS
			const spriteSheets = [
				{
					name: data.name + 'SpriteSheet',
					url: getRealUrl(doorFile),
					frameSize: data.frame_size,
					animations: animations,
				},
			]

			// ----- FORMATED DATA
			const formatedData = {
				id: data.id_world_door
					? data.id_world_door
					: data.id_sub_world_door,
				goesToWorld: data.id_world_door ? true : false,
				name: data.name,
				coordinates: data.coordinates,
				spriteSheets,
				// isAvailable: false,
				isAvailable: data.id_world_door ? data.enable_door : true, // *****
				// : actualDoor.current.coordinates === data.coordinates, ***$$$

				starsData: {
					total: data.stars?.possible_stars,
					earned: data.stars?.earned_stars,
					minPercentageToUnlock: data.required_stars_percentage,
				},

				badgeData: {
					coordinates: data.badge_coordinates,
					file: getRealUrl(data?.badge_file ?? ''), //data.badge_file, *****
				},
			}
			sceneDataFormated.doors.push(formatedData)
		}

		// ----- $NPCS
		for (const data of sceneData.current?.npcs ?? []) {
			const NPC_ID = data.id_sub_world_scene_npc_appearance
			// ----- SPRITE SHEETS
			const spriteSheets = []
			for (const spriteSheet of data.sprite_sheets) {
				let imageFile = spriteSheet.image_file

				if (spriteSheet.resize?.exist_file && !isIOS) {
					imageFile = spriteSheet.resize.image_file
				}

				const formatedSpriteSheet = {
					name:
						data.sprite_sheets[0].npc.name +
						spriteSheet.npc_state.name +
						'SpriteSheet',
					url: getRealUrl(imageFile),
					frameSize: spriteSheet.frame_size,
					animations: [
						{
							name: '-' + spriteSheet.npc_state.name,
							frames: {
								start: 0,
								end: spriteSheet.frames_amount - 1,
							},
							duration: spriteSheet.frames_duration,
							repeat:
								spriteSheet.npc_state.name === 'idle' ? -1 : 0,
						},
					],
				}
				spriteSheets.push(formatedSpriteSheet)

				// ----- SPRITESHEET SOUND
				if (spriteSheet.sound?.audio_file)
					sceneDataFormated.sounds.spriteSheets.push(
						getAudioFormat(null, spriteSheet.sound?.audio_file)
					)
			}

			// ----- NPC SOUND
			if (data.dialogues[0]?.audio_file)
				sceneDataFormated.sounds.NPCS[NPC_ID] = getAudioFormat(
					NPC_ID,
					data.dialogues[0]?.audio_file
				)

			// ----- GAME SOUND
			if (data.missions[0]?.game?.sound) {
				sceneDataFormated.sounds.games[NPC_ID] = getAudioFormat(
					NPC_ID,
					data.missions[0].game.sound?.audio_file,
					true,
					false,
					true
				)
			}

			// ----- DIALOGUES
			const dialogues = []
			for (const dialogue of data.dialogues) {
				// *****
				const formatedDialogue = {
					text: dialogue.text,
					translation: dialogue.text_traduction,
					sound: { file: dialogue?.audio_file },
				}
				dialogues.push(formatedDialogue)
			}

			// ----- FORMATED DATA
			const formatedData = {
				id: NPC_ID,
				name: data.sprite_sheets[0].npc.name,
				coordinates: data.coordinates,
				type: data.missions[0]?.challenge[0].category,
				spriteSheets: spriteSheets,
				dialogues: dialogues,
				missions: {
					id: data.missions[0].content[0].mission,
					reward: data.missions[0].challenge[0].reward,
					xp: data.missions[0].challenge[0].xp,
					content: data.missions[0].content,
				},
				gameType: data.missions[0].game.name,
				starsData: { earned: data.missions[0].earned_star },
				profileImage: getRealUrl(
					data.sprite_sheets[0].npc.silence_file
				),
			}
			sceneDataFormated.npcs.push(formatedData)
		}

		// ----- $DECORATIONS
		for (const data of sceneData.current?.decorations ?? []) {
			// ----- SPRITE SHEETS
			if (data.animation.sound?.audio_file)
				sceneDataFormated.sounds.spriteSheets.push(
					getAudioFormat(data.animation.sound?.audio_file)
				)

			let imageFile = data.animation.image_file
			if (data.resize?.exist_file && !isIOS) {
				imageFile = data.resize.image_file
			}

			const spriteSheets = [
				{
					name: data.animation.name + 'SpriteSheet',
					url: getRealUrl(imageFile),
					frameSize: data.animation.frame_size,
					animations: [
						{
							name: '-idle',
							frames: {
								start: 0,
								end: data.animation.frames_amount - 1,
							},
							duration: data.animation.frames_duration,
							repeat: -1,
						},
					],
				},
			]

			// ----- FORMATED DATA
			const formatedData = {
				id: data.id_sub_world_scene_decoration,
				name: data.animation.name,
				coordinates: data.coordinates,
				spriteSheets: spriteSheets,
				hidden: data.hidden,
				isSuperimposed: data.is_superimposed,
			}
			if (data.hidden) sceneDataFormated.hasFinalScene = true
			sceneDataFormated.decorations.push(formatedData)
		}

		// ----- $LOST ITEMS
		for (const data of sceneData.current?.mission_lost_object ?? []) {
			// ----- SPRITE SHEETS
			const name = data.mission_profile[0].id_mission_profile
			const spriteSheets = [
				{
					name: name + 'SpriteSheet',
					url: getRealUrl(
						data.mission_profile[0].mission.mission_content
							.mission_content_add_ons.object_image_file
					),
					frameSize:
						data.mission_profile[0].mission.mission_content
							.mission_content_add_ons.frame_size,
					animations: [],
				},
			]

			// ----- FORMATED DATA
			const formatedData = {
				id: data.mission_profile[0].mission.id_mission,
				name: name,
				coordinates:
					data.mission_profile[0].mission.mission_content
						.mission_content_add_ons.coordinates,
				spriteSheets: spriteSheets,

				mission: {
					id: data.mission_profile[0].id_mission_profile,
					reward: data.mission_profile[0].mission.challenge.reward,
					xp: data.mission_profile[0].mission.challenge.xp,
					message:
						data.mission_profile[0].mission.mission_content.text,
					completed: data.object_catched,
				},
			}
			sceneDataFormated.lostItems.push(formatedData)
		}

		return sceneDataFormated
	}

	return (
		<>
			<updateProfileContext.Provider
				value={[
					profileData.current,
					updateProfileData,
					sceneData.current,
					goToNextScene,
				]}>
				{dataWasSuccessfullyFetched && (
					<SceneProvider>
						<Scene
							levelResume={levelResume.current}
							profileData={profileData.current}
							sceneData={sceneData.current}
							actualDoor={actualDoor.current}
							goToNextScene={goToNextScene}
							updateLoadingBarPercentage={
								updateLoadingBarPercentage
							}
							loadingBarWasCompleted={loadingBarWasCompleted}
							reload={reload} // SOLO PARA RECARGAR Y NO CAMBIAR EL CODIGO DE USERDATA >:V
						/>
					</SceneProvider>
				)}
			</updateProfileContext.Provider>
		</>
	)
}

export { SceneDataFetch }

import React, { useContext, useEffect, useState } from 'react'
import {
	GET_DOORS_BY_SCENE,
	GET_SUBWORLD_DOORS_BY_SCENE,
} from '../../../../utils/constantsAdmin'
import ModalContext from '../../../../context/editor/ModalContext'
import { Pagination } from '../../searchAndPagination/Pagination'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import CollapseDoorSceneWorld from './CollapseDoorSceneWorld'
import FormDoorSceneWorld from './FormDoorSceneWorld'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { LoadingIcon } from '../../LoadingIcon'
import { useTranslation } from 'react-i18next'

export const ListDoorScene = ({ idScene }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { gettypeContextSpace } = ManageStorageAdminDesigner()

	// REFRESH DOORS
	const [refreshDoors, setRefreshDoors] = useState(false)

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// DATA
	const [dataSceneDoor, setDataSceneDoor] = useState(undefined)

	// GET ALL DOORS
	useEffect(() => {
		const getData = async () => {
			let url
			if (gettypeContextSpace() === 'World') {
				url = GET_DOORS_BY_SCENE + idScene + '/'
			} else if (gettypeContextSpace() === 'Subworld') {
				url = GET_SUBWORLD_DOORS_BY_SCENE + idScene + '/'
			}
			try {
				const result_data = await axiosSupreme('get', url)
				setDataSceneDoor(result_data)
			} catch (e) {
				console.log('Error get Data: ', e)
			}
		}
		getData()
	}, [refreshDoors])

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// ADD DOOR
	const OpenAddDoorModal = () => {
		setTitleModal(t('pages.editor.components.list_door_scene.title'))
		setContentModal(
			<FormDoorSceneWorld
				idScene={idScene}
				setRefreshDoors={setRefreshDoors}
			/>
		)
		openModal(true)
	}
	// ACTIVECOLLAPSE
	const [activeCollapseDoors, setActiveCollapseDoors] = useState(undefined)

	return (
		<div className='doorSceneWorld'>
			<div className='doorSceneWorld__btnAdd'>
				<h1>
					{t('pages.editor.components.list_door_scene.escene_doors')}
				</h1>
				<div className='boxBtnAdd'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddDoorModal}>
						{t('common.add')}
					</button>
				</div>
			</div>
			<div className='doorSceneWorld__content'>
				{dataSceneDoor !== undefined ? (
					<>
						{dataSceneDoor === 'search' ||
						dataSceneDoor.results.length > 0 ? (
							<>
								<SearchCamp
									setData={setDataSceneDoor}
									setRefreshDataList={setRefreshDoors}
									url={
										gettypeContextSpace() === 'World'
											? GET_DOORS_BY_SCENE + idScene + '/'
											: GET_SUBWORLD_DOORS_BY_SCENE +
											  idScene +
											  '/'
									}
									search={search}
									setSearch={setSearch}
								/>
								{dataSceneDoor === 'search' ? (
									<p className='noSelect'>
										{t(
											'pages.editor.components.list_door_scene.none_doors'
										)}
									</p>
								) : null}
							</>
						) : (
							<p className='noSelect'>
								{t(
									'pages.editor.components.list_door_scene.scene_no_doors_assigned'
								)}
							</p>
						)}
						{dataSceneDoor !== 'search' ? (
							<div className='doorSceneWorld__content__listDoors'>
								{dataSceneDoor.results.map(
									(data_item, index) => (
										<CollapseDoorSceneWorld
											key={
												gettypeContextSpace() ===
												'World'
													? data_item.id_world_door
													: data_item.id_sub_world_door
											}
											idScene={idScene}
											objectDoor={data_item}
											setRefreshDoors={setRefreshDoors}
											activeCollapseDoors={
												activeCollapseDoors
											}
											setActiveCollapseDoors={
												setActiveCollapseDoors
											}></CollapseDoorSceneWorld>
									)
								)}
								<Pagination
									data={dataSceneDoor}
									setData={setDataSceneDoor}></Pagination>
							</div>
						) : null}
					</>
				) : (
					<LoadingIcon />
				)}
			</div>
		</div>
	)
}

import { useEffect, useRef, useState } from 'react'
import '../../styles/Lesson.scss'

import { useActivity } from '../../provider/ActivityProvider'
import AudioGame from '../fragments/AudioGame'
import AudioHelpButton from '../fragments/AudioHelpButton'
import { useSoundAction } from '../../hooks/useSoundAction'
import { StepSlidingBar } from '../gui/StepSlidingBar'
import { useTranslation } from 'react-i18next'

export default function Lesson({ onClose, onComplete }) {
	const { t } = useTranslation()
	const started = useRef(false)
	const {
		soundAction,
		playAudioBackground,
		pauseAudioBackground,
		playAudioAmbientSound,
		pauseAudioAmbientSound,
	} = useSoundAction()
	const { wordsFragments } = useActivity()
	const [state, setState] = useState('listening') // normal, transitioning
	const [currentAudioSrc, setCurrentAudioSrc] = useState('')
	const [actualIndex, setActualIndex] = useState(0)
	const currentIndexRef = useRef(0)
	const mainSliderStyle = {
		left: '-' + actualIndex * 100 + '%',
	}
	const audioLimitTimeout = useRef(null)

	function startTransition(forward = true) {
		if (forward) {
			if (actualIndex >= wordsFragments.length - 1) {
				return
			} else if (actualIndex >= wordsFragments.length - 2) {
				onComplete()
			}
			if (state === 'normal') {
				setState('transitioning')
				setActualIndex(actualIndex + 1)
				currentIndexRef.current = actualIndex + 1
				const timeout = setTimeout(() => {
					clearTimeout(timeout)
					endTransition()
				}, 700) // 100 plus
			}
		} else {
			if (actualIndex <= 0) {
				return
			}
			if (state === 'normal') {
				setState('transitioning')
				setActualIndex(actualIndex - 1)
				currentIndexRef.current = actualIndex - 1
				const timeout = setTimeout(() => {
					clearTimeout(timeout)
					endTransition()
				}, 700) // 100 plus
			}
		}
	}

	function endTransition() {
		firstAudio()
	}

	function firstAudio() {
		setState('listening')
		// clearTimeout(audioLimitTimeout.current)
		// audioLimitTimeout.current = setTimeout(() => {
		// 	handleAudioEnd(true)
		// }, 2000)
		playAudio()
	}

	function playAudio() {
		setCurrentAudioSrc(wordsFragments[currentIndexRef.current].audio)
	}

	function handleAudioEnd(isListening) {
		if (!started.current) return
		clearTimeout(audioLimitTimeout.current)
		if (state === 'listening' || isListening) {
			setState('normal')
		}
	}

	function onNext() {
		startTransition()
		soundAction('effect', 'TLSG8', 'play')
	}

	function onBack() {
		startTransition(false)
		soundAction('effect', 'TLSG8', 'play')
	}

	function handleCloseBtn() {
		soundAction('effect', 'TLSG2', 'play')
		onClose()
	}

	useEffect(() => {
		pauseAudioBackground()
		pauseAudioAmbientSound()

		if (wordsFragments.length === 1) {
			onComplete()
		}
		const timeout = setTimeout(() => {
			clearTimeout(timeout)
			started.current = true
			firstAudio()
		}, 1250)

		return () => {
			playAudioBackground()
			playAudioAmbientSound()
		}
	}, [])

	return (
		<div className='lesson'>
			<button className='lesson__close' onClick={handleCloseBtn}></button>
			<div className='lesson__upper'>
				<StepSlidingBar
					stepsAmount={wordsFragments.length}
					actualStep={actualIndex + 1}
				/>
			</div>
			<div className='lesson__main'>
				<div className='lesson__main__slider' style={mainSliderStyle}>
					{wordsFragments.map((section, index) => (
						<Section
							key={index}
							word={section.word}
							translation={section.translation}
							image={section.image}
							audio={section.audio}
							playingOtherAudio={state === 'listening'}
						/>
					))}
				</div>
			</div>
			<div className='lesson__bottom'>
				{actualIndex > 0 && (
					<button
						className='lesson__next'
						disabled={state !== 'normal'}
						onClick={onBack}>
						{t('common.btn_prev')}
					</button>
				)}
				<button
					className='lesson__next'
					style={{ marginLeft: 'auto' }}
					disabled={state !== 'normal'}
					onClick={
						actualIndex >= wordsFragments.length - 1
							? onClose
							: onNext
					}>
					{actualIndex >= wordsFragments.length - 1
						? t('common.btn_finish')
						: t('common.btn_next')}
				</button>
			</div>

			<AudioGame
				src={currentAudioSrc}
				handleAudioStart={() => {}}
				handleAudioEnd={handleAudioEnd}
			/>
		</div>
	)
}

const Section = ({ word, translation, image, audio, playingOtherAudio }) => {
	return (
		<div className='lesson__section'>
			<div className='lesson__section__card'>
				<div className='lesson__section__card__two'>
					<AudioHelpButton
						src={audio}
						style={{
							bottom: '0',
							position: 'relative',
						}}
						styleChild={{
							width: '50px',
							height: '50px',
							margin: 0,
						}}
						playingOtherAudio={playingOtherAudio}
					/>
					<div className='lesson__section__card__text'>
						<div>{word}</div>
					</div>
				</div>
				<div className='lesson__section__card__three'>
					<div className='lesson__section__card__translation'>
						<div>{translation}</div>
					</div>
				</div>
			</div>
		</div>
	)
}

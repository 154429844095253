import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/Apps.scss'
import App from './routes/App'

import '@fontsource-variable/nunito-sans/wght-italic.css'
import '@fontsource-variable/nunito-sans/wght.css'
import '@fontsource/philosopher/700-italic.css'
import '@fontsource/philosopher/700.css'
import '@fontsource-variable/league-spartan/wght.css'
// import '@fontsource/poppins/100-italic.css'
import '@fontsource/poppins/100.css'
// import '@fontsource/poppins/200-italic.css'
import '@fontsource/poppins/200.css'
// import '@fontsource/poppins/300-italic.css'
import '@fontsource/poppins/300.css'
// import '@fontsource/poppins/400-italic.css'
import '@fontsource/poppins/400.css'
// import '@fontsource/poppins/500-italic.css'
import '@fontsource/poppins/500.css'
// import '@fontsource/poppins/600-italic.css'
import '@fontsource/poppins/600.css'
// import '@fontsource/poppins/700-italic.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800-italic.css'
import '@fontsource/poppins/800.css'
import '@fontsource/poppins/900-italic.css'
import '@fontsource/poppins/900.css'
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(
// 	<React.StrictMode>
// 		<App />
// 	</React.StrictMode>
// )
root.render(<App />)

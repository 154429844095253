// IMPORTS
import { Alert } from '../../../components/editor/Alert'
import Modal from '../../../components/editor/Modal'
import ModalDelete from '../../../components/editor/ModalDelete'
import TitleAdministratorAndMenu from '../../../components/editor/TitleAdministratorAndMenu'
import { BackButton } from '../../../components/editor/BackButton'
import { ListAnimations } from '../../../components/editor/design/Animations/ListAnimations'
import { ErrorPerms } from '../../../components/editor/ErrorPerms'
// CONTEXT AND HOOKS
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'
// STYLES
import '../../../styles/AdminDesign.scss'

export default function ManageAnimations() {
	return useAuthNavigator(<PrivatePage />)
}

function PrivatePage() {
	// AUTH PERMISSION
	const { gameUser } = useAuthProvider()

	return (
		<div className='objectsAdmin'>
			{'view_administrator_game' in gameUser.permissions ? (
				<>
					<Alert />
					<Modal />
					<ModalDelete />
					<BackButton url={'/manage-scene-world'}></BackButton>
					<div className='animations'>
						<div className='animations__container'>
							<TitleAdministratorAndMenu name='ANIMACIONES' />

							<div className='adminD'>
								<div className='adminDesign'>
									<div className='adminDesign__container ExtendManageExternal'>
										<div className='adminDesign__container__mainContainerBank'>
											<div className='adminDesign__container__mainContainerBank__Item'>
												<div className='adminDesign__container__mainContainerBank__Item__content'>
													<ListAnimations />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<ErrorPerms />
			)}
		</div>
	)
}

import React, { useContext, useEffect, useState } from 'react'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_MANAGE_STORY_TELLER,
	API_MANAGE_STORY_TELLER_SUB_WORLD,
} from '../../../../utils/constantsAdmin'

import SaveButton from '../../SaveButton'
import {
	onInputChangeDefault,
	processErrors,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormStoryTeller = ({
	idScene,
	setRefreshStorysTeller,
	stateObjectStoryTeller,
	setStateObjectStoryTeller,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// LOCAL STORAGE
	const { gettypeContextSpace } = ManageStorageAdminDesigner()

	// ALERT, MODAL
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setDescription('')
	}
	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// SET LEVEL WHEN UPDATE
	useEffect(() => {
		if (stateObjectStoryTeller !== undefined) {
			setName(stateObjectStoryTeller.name)
			setDescription(stateObjectStoryTeller.description)
		} else {
			clearFields()
		}
	}, [stateObjectStoryTeller])

	// SUBMIT FORM STORYTELLER
	const onSubmitStoryTeller = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		formData.append('description', event.target.elements.description.value)
		if (gettypeContextSpace() === 'World') {
			formData.append('sub_world_scene', '')
			formData.append('world_scene', idScene)
		} else {
			formData.append('world_scene', '')
			formData.append('sub_world_scene', idScene)
		}

		let urlRequest =
			gettypeContextSpace() === 'World'
				? API_MANAGE_STORY_TELLER
				: API_MANAGE_STORY_TELLER_SUB_WORLD
		if (stateObjectStoryTeller === undefined) {
			// SAVE
			manageStoryTeller(event, urlRequest, formData, 'add')
		} else {
			// UPDATE
			manageStoryTeller(
				event,
				(urlRequest +=
					(gettypeContextSpace() === 'World'
						? stateObjectStoryTeller.id_storyteller
						: stateObjectStoryTeller.id_storyteller) + '/'),
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE LEVEL
	const manageStoryTeller = async (event, url, obj, action) => {
		try {
			// PROCESS DATA
			await axiosSupreme(action === 'add' ? 'post' : 'patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshStorysTeller((prev) => !prev)
			if (action === 'update') getNewObject()
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		let urlRequest =
			gettypeContextSpace() === 'World'
				? API_MANAGE_STORY_TELLER
				: API_MANAGE_STORY_TELLER_SUB_WORLD
		const result_data = await axiosSupreme(
			'get',
			(urlRequest +=
				(gettypeContextSpace() === 'World'
					? stateObjectStoryTeller.id_storyteller
					: stateObjectStoryTeller.id_storyteller) + '/'),
			undefined
		)
		setStateObjectStoryTeller(result_data)
	}

	return (
		<form onSubmit={onSubmitStoryTeller}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_storyteller.name')}
				</label>
				<input
					maxLength='45'
					name='name'
					className='admin__container__inputs__in'
					id='name'
					type='text'
					placeholder={t(
						'pages.editor.components.form_storyteller.name_placeholder'
					)}
					autoComplete='off'
					value={name}
					onChange={(event) => onInputChangeDefault(event, setName)}
					required></input>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_storyteller.description_and_placeholder'
					)}
				</label>
				<textarea
					maxLength='500'
					name='description'
					className='admin__container__inputs__in'
					id='description'
					type='text-area'
					placeholder={t(
						'pages.editor.components.form_storyteller.description_and_placeholder'
					)}
					autoComplete='off'
					value={description}
					onChange={(event) =>
						onInputChangeDefault(event, setDescription)
					}
					required></textarea>
			</div>
			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

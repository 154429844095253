import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const SceneConfigContext = createContext(null)

const useSceneConfig = () => {
	return useContext(SceneConfigContext)
}

const SceneConfigProvider = ({ children, loadingIsVisible }) => {
	const { t } = useTranslation()
	const [soundData, setSoundData] = useState(() => {
		const music = localStorage.getItem('MUSIC_IS_ACTIVE')
		const effects = localStorage.getItem('EFFECTS_IS_ACTIVE')
		const voices = localStorage.getItem('VOICES_IS_ACTIVE')

		return [
			{
				name: t(
					'pages.game.components.configuration_panel.components_pages.sound.music'
				),
				volume: isNaN(Number(music)) ? '1' : music > 1 ? 1 : music,
			},
			{
				name: t(
					'pages.game.components.configuration_panel.components_pages.sound.sound_effects'
				),
				volume: isNaN(Number(effects))
					? '1'
					: effects > 1
					? 1
					: effects,
			},
			{
				name: t(
					'pages.game.components.configuration_panel.components_pages.sound.voices'
				),
				volume: isNaN(Number(voices)) ? '1' : voices > 1 ? 1 : voices,
			},
		]
	})

	useEffect(() => {
		const [music, effects, voices] = soundData

		localStorage.setItem(
			'MUSIC_IS_ACTIVE',
			music.volume > 1 ? 1 : music.volume
		)
		localStorage.setItem(
			'EFFECTS_IS_ACTIVE',
			effects.volume > 1 ? 1 : effects.volume
		)
		localStorage.setItem(
			'VOICES_IS_ACTIVE',
			voices.volume > 1 ? 1 : voices.volume
		)
	}, [soundData])

	const soundPointers = {
		music: soundData[0],
		effects: soundData[1],
		voices: soundData[2],
	}

	return (
		<SceneConfigContext.Provider
			value={{
				soundData,
				setSoundData,
				soundPointers,
				loadingIsVisible,
			}}>
			{children}
		</SceneConfigContext.Provider>
	)
}

export default SceneConfigProvider
export { useSceneConfig }

import TitleAdministratorAndMenu from '../../../components/editor/TitleAdministratorAndMenu'
import { Alert } from '../../../components/editor/Alert'
import Modal from '../../../components/editor/Modal'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import { BackButton } from '../../../components/editor/BackButton'
import ModalDelete from '../../../components/editor/ModalDelete'
import { ErrorPerms } from '../../../components/editor/ErrorPerms'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'
import { ListSounds } from '../../../components/editor/design/Sounds/ListSounds'

export default function ManageNpc() {
	return useAuthNavigator(<PrivatePage />)
}
const PrivatePage = () => {
	// AUTH PERMISSION
	const { gameUser } = useAuthProvider()

	// RESET SCROLL
	window.scroll(0, 0)

	return (
		<div className='objectsAdmin'>
			{'view_administrator_game' in gameUser.permissions ? (
				<>
					<Alert />
					<Modal />
					<ModalDelete />
					<TitleAdministratorAndMenu name='SONIDOS' />
					<BackButton url={'/manage-scene-world'} />
					<div className='adminD'>
						<div className='adminDesign'>
							<div className='adminDesign__container ExtendManageExternal'>
								<div className='adminDesign__container__mainContainerBank'>
									<div className='adminDesign__container__mainContainerBank__Item'>
										<div className='adminDesign__container__mainContainerBank__Item__content'>
											<ListSounds />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<ErrorPerms />
			)}
		</div>
	)
}

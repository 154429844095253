import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import {
	API_EXCHANGE_MISSION,
	API_MANAGE_LIST_LEVELS_LANGUAGES,
	GET_MISSION_BY_SCENE_SUB_WORLD,
	GET_SCENE_SUBWORLD_BY_SUBWORLD,
	GET_SUB_WORLD_BY_WORLD,
	GET_WORLDS_BY_LEVEL,
} from '../../../../utils/constantsAdmin'
import {
	onInputChangeDefault,
	returnProcessUrl,
} from '../../../../utils/general_functions_forms'
import SaveButton from '../../SaveButton'
import { LoadingIcon } from '../../LoadingIcon'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { ManageStorageGameLanguage } from '../../../../utils/manage_local_storage'
import { useFetchOptions } from '../../../../hooks/useFetchOptions/useFetchOptions'

export const FormExchangeMission = ({ objMission, setRefreshNpcsScene }) => {
	const { t } = useTranslation()

	// CONTEXT
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)
	// MODAL
	const { openModal } = useContext(ModalContext)
	// LOCAL STORAGE
	const { getGameLanguage } = ManageStorageGameLanguage()

	// VARS
	const [levelLanguage, setLevelLanguage] = useState('')
	const [world, setWorld] = useState('')
	const [subWorld, setSubWorld] = useState('')
	const [sceneSubWorld, setSceneSubWorld] = useState('')
	const [npcSceneSubWorld, setNpcSceneSubWorld] = useState('')

	// LISTS
	const [listLevelLanguages, setListLevelLanguages] = useState({})
	const [listWorlds, setListWorlds] = useState({})
	const [listSubworlds, setListSubworlds] = useState({})
	const [listSceneSubWorlds, setListSceneSubWorlds] = useState({})
	const [listNpcSceneSubWorlds, setListNpcSceneSubWorlds] = useState({})

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	const abortControllerChangeLevelLanguage = useRef()
	const abortControllerChangeWorld = useRef()
	const abortControllerChangeSubWorld = useRef()
	const abortControllerChangeSceneSubWorld = useRef()

	const { fetchOptions } = useFetchOptions()

	useEffect(() => {
		const abortController = new AbortController()
		let idGameLanguage = JSON.parse(getGameLanguage())
		const updateDataList = (dataList) => {
			setListLevelLanguages((prevData) => ({
				...prevData,
				...dataList.reduce((acc, dataItem) => {
					acc[dataItem.id_level_language] = { name: dataItem.name }
					return acc
				}, {}),
			}))
		}

		fetchOptions(
			`${API_MANAGE_LIST_LEVELS_LANGUAGES}${idGameLanguage.id_game_language}/`,
			updateDataList,
			abortController.signal
		)

		return () => {
			abortController.abort()
		}
	}, [])

	const onChangeLevelLanguage = (value) => {
		setLevelLanguage(value)
		setWorld('')
		setSubWorld('')
		setSceneSubWorld('')
		setListWorlds({})
		setListSubworlds({})
		setListSceneSubWorlds({})
		setListNpcSceneSubWorlds({})
		if (value) {
			if (
				abortControllerChangeLevelLanguage.current instanceof
				AbortController
			)
				abortControllerChangeLevelLanguage.current.abort()
			abortControllerChangeLevelLanguage.current = new AbortController()
			const updateDataList = (dataList) => {
				setListWorlds((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_world] = { name: dataItem.name }
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				`${GET_WORLDS_BY_LEVEL}${value}/`,
				updateDataList,
				abortControllerChangeLevelLanguage.current.signal
			)
		}
	}

	const onChangeWorld = (value) => {
		setWorld(value)
		setSubWorld('')
		setSceneSubWorld('')
		setListSubworlds({})
		setListSceneSubWorlds({})
		setListNpcSceneSubWorlds({})
		if (value) {
			if (abortControllerChangeWorld.current instanceof AbortController)
				abortControllerChangeWorld.current.abort()
			abortControllerChangeWorld.current = new AbortController()
			const updateDataList = (dataList) => {
				setListSubworlds((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_sub_world] = { name: dataItem.name }
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				`${GET_SUB_WORLD_BY_WORLD}${value}/`,
				updateDataList,
				abortControllerChangeWorld.current.signal
			)
		}
	}

	const onChangeSubWorld = (value) => {
		setSubWorld(value)
		setSceneSubWorld('')
		setListSceneSubWorlds({})
		setListNpcSceneSubWorlds({})
		if (value) {
			if (
				abortControllerChangeSubWorld.current instanceof AbortController
			)
				abortControllerChangeSubWorld.current.abort()
			abortControllerChangeSubWorld.current = new AbortController()
			const updateDataList = (dataList) => {
				setListSceneSubWorlds((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_scene_sub_world] = {
							name: dataItem.name,
						}
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				`${GET_SCENE_SUBWORLD_BY_SUBWORLD}${value}/`,
				updateDataList,
				abortControllerChangeSubWorld.current.signal
			)
		}
	}

	const onChangeSceneSubWorld = (value) => {
		setSceneSubWorld(value)
		setListNpcSceneSubWorlds({})
		if (value) {
			if (
				abortControllerChangeSceneSubWorld.current instanceof
				AbortController
			)
				abortControllerChangeSceneSubWorld.current.abort()
			abortControllerChangeSceneSubWorld.current = new AbortController()

			const updateDataList = (dataList) => {
				setListNpcSceneSubWorlds((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						const mission =
							dataItem.challenge.category === 'l'
								? 'Clase'
								: dataItem.challenge.category === 'o'
								? 'Objeto Perdido'
								: dataItem.challenge.category === 'g'
								? 'Juego'
								: dataItem.challenge.category === 'ee'
								? 'Huevo de Pascua'
								: 'No reconocido'

						acc[dataItem.sub_world_scene_npc_appearance] = {
							name: `${dataItem.npc.name} - ${mission}`,
						}

						return acc
					}, {}),
				}))
			}

			fetchOptions(
				`${GET_MISSION_BY_SCENE_SUB_WORLD}${value}/`,
				updateDataList,
				abortControllerChangeSceneSubWorld.current.signal
			)
		}
	}

	const onSubmitExchange = async (e) => {
		try {
			e.preventDefault()
			setStateLoading(true)
			// MAKE DICT
			let dataSend = new FormData()
			dataSend.append(
				'sub_world_scene_npc_appearance',
				objMission.sub_world_scene_npc_appearance
			)
			dataSend.append(
				'new_sub_world_scene_npc_appearance',
				npcSceneSubWorld
			)
			await axiosSupreme(
				'patch',
				API_EXCHANGE_MISSION + objMission.id_mission + '/',
				dataSend
			)
			setRefreshNpcsScene((prev) => !prev)
			setTypeMessage('success')
			setMessage(t('common.modified'))
			showAlert()
			openModal(true)
		} catch (error) {
			setTypeMessage('error')
			setMessage(error)
		} finally {
			setStateLoading(false)
		}
	}

	return (
		<form action='' onSubmit={onSubmitExchange}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.lang_level'
					)}
				</label>
				<select
					name='level_language'
					className='admin__container__inputs__in'
					id='level_language'
					placeholder={t(
						'pages.editor.components.form_content_missions.placeholder_stars'
					)}
					autoComplete='off'
					value={levelLanguage}
					onChange={(event) =>
						onChangeLevelLanguage(event.target.value)
					}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_content_missions.select'
						)}
					</option>
					{Object.keys(listLevelLanguages).map((key) => (
						<option key={key} value={key}>
							{listLevelLanguages[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_content_missions.world')}
				</label>
				<select
					name='level_language'
					className='admin__container__inputs__in'
					id='level_language'
					placeholder={t(
						'pages.editor.components.form_content_missions.placeholder_stars'
					)}
					autoComplete='off'
					value={world}
					onChange={(event) => onChangeWorld(event.target.value)}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_content_missions.select'
						)}
					</option>
					{Object.keys(listWorlds).map((key) => (
						<option key={key} value={key}>
							{listWorlds[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.sub_world'
					)}
				</label>
				<select
					name='subworld'
					className='admin__container__inputs__in'
					id='subworld'
					placeholder={t(
						'pages.editor.components.form_content_missions.placeholder_stars'
					)}
					autoComplete='off'
					value={subWorld}
					onChange={(event) => onChangeSubWorld(event.target.value)}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_content_missions.select'
						)}
					</option>
					{Object.keys(listSubworlds).map((key) => (
						<option key={key} value={key}>
							{listSubworlds[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.sub_world_escene'
					)}
				</label>
				<select
					name='scene_subworld'
					className='admin__container__inputs__in'
					id='scene_subworld'
					placeholder={t(
						'pages.editor.components.form_content_missions.placeholder_stars'
					)}
					autoComplete='off'
					value={sceneSubWorld}
					onChange={(event) =>
						onChangeSceneSubWorld(event.target.value)
					}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_content_missions.select'
						)}
					</option>
					{Object.keys(listSceneSubWorlds).map((key) => (
						<option key={key} value={key}>
							{listSceneSubWorlds[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.mission_sub_world_escene'
					)}
				</label>
				<select
					name='scene_subworld'
					className='admin__container__inputs__in'
					id='scene_subworld'
					placeholder={t(
						'pages.editor.components.form_content_missions.placeholder_stars'
					)}
					autoComplete='off'
					value={npcSceneSubWorld}
					onChange={(event) =>
						onInputChangeDefault(event, setNpcSceneSubWorld)
					}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_content_missions.select'
						)}
					</option>
					{Object.keys(listNpcSceneSubWorlds).map((key) => (
						<option key={key} value={key}>
							{listNpcSceneSubWorlds[key].name}
						</option>
					))}
				</select>
			</div>

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton />
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

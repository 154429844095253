import React, { useContext, useEffect, useRef, useState } from 'react'
import SaveButton from '../../SaveButton'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { API_MANAGE_STARS } from '../../../../utils/constantsAdmin'

import {
	onInputChangeDefault,
	onInputChangeImage,
	processErrors,
} from '../../../../utils/general_functions_forms'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'
import { LoadingIcon } from '../../LoadingIcon'

export const FormStars = ({
	stateObjectStar,
	setStateObjectStar,
	setRefreshStars,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const imageFile = useRef(null)

	// ERROR FOR FORM
	const [errorImage, setErrorImage] = useState('')

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		imageFile.current.value = null
	}

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// SET STAR WHEN UPDATE
	useEffect(() => {
		if (stateObjectStar !== undefined) {
			setName(stateObjectStar.name)
		} else {
			clearFields()
		}
	}, [stateObjectStar])

	// SUBMIT FORM ANIMATION
	const onSubmitStars = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		if (event.target.elements.image_file.value) {
			formData.append(
				'image_file',
				event.target.elements.image_file.files[0]
			)
		}
		if (stateObjectStar === undefined) {
			// SAVE
			manageStars(event, API_MANAGE_STARS, formData, 'add')
		} else {
			// UPDATE
			manageStars(
				event,
				API_MANAGE_STARS + stateObjectStar.id_star + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE ANIMATION
	const manageStars = async (event, url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshStars((prev) => !prev)
			if (action === 'update') setStateObjectStar(result_data)
			setStateLoading(false)
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.cannot_delete'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	return (
		<form onSubmit={onSubmitStars}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_stars.name')}
				</label>
				<input
					maxLength='45'
					name='name'
					className='admin__container__inputs__in'
					id='name'
					type='text'
					placeholder={t(
						'pages.editor.components.form_stars.name_placeholder'
					)}
					autoComplete='off'
					value={name}
					onChange={(event) => onInputChangeDefault(event, setName)}
					required></input>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_stars.images_star')}
				</label>
				{stateObjectStar !== undefined ? (
					<>
						{stateObjectStar.image_file !== null ? (
							<label htmlFor=''>
								{t('common.current_file')}
								<a
									href={stateObjectStar.image_file}
									target='_blank'
									rel='noopener noreferrer'>
									{t('common.open')}
								</a>
							</label>
						) : null}
					</>
				) : null}
				<input
					name='image_file'
					className='admin__container__inputs__in'
					id='image_file'
					type='file'
					ref={imageFile}
					autoComplete='off'
					onChange={(event) =>
						onInputChangeImage(event, setErrorImage, imageFile)
					}
					accept='image/*'
					required={stateObjectStar === undefined}></input>
				{errorImage && <p>{errorImage}</p>}
			</div>
			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton />
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

import { useContext, useEffect, useState } from 'react'
import { ObjectLevelLanguageContext } from './ObjectLevelLanguage'
import { ManageStorageLevelLanguage } from '../../../../utils/manage_local_storage'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { API_MANAGE_LEVELS_LANGUAGES } from '../../../../utils/constantsAdmin'

export const ItemButtonLevelLanguage = ({
	idLevelLanguage,
	nameLevelLanguage,
	setOpenLevels,
	openContentAdmin,
}) => {
	const { axiosSupreme } = useAuthProvider()
	// LOCAL STORAGE
	const { setLevelLanguage } = ManageStorageLevelLanguage()

	// CONTEXT OBJECT LANGUAGE
	const { objectLevelLanguage, setObjectLevelLanguage } = useContext(
		ObjectLevelLanguageContext
	)

	// VALUES
	const [name, setName] = useState('')

	useEffect(() => {
		if (
			objectLevelLanguage !== undefined &&
			objectLevelLanguage.id_level_language === idLevelLanguage
		) {
			setName(objectLevelLanguage.name)
		} else {
			setName(nameLevelLanguage)
		}
	}, [objectLevelLanguage])

	// ONCLICK FOR LEVEL LANGUAJE
	const clickLevelLanguage = async (idLevelLanguage) => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_LEVELS_LANGUAGES + idLevelLanguage + '/',
				undefined
			)
			openContentAdmin(setOpenLevels)
			setObjectLevelLanguage(result_data)
			setLevelLanguage(idLevelLanguage)
		} catch (e) {
			console.log('Error get Data: ', e)
			clickLevelLanguage(idLevelLanguage)
		}
	}

	return (
		<button
			className={
				objectLevelLanguage !== undefined &&
				objectLevelLanguage.id_level_language === idLevelLanguage
					? 'adminDesign__menu__links__btns__btnLevelLanguageActive'
					: 'adminDesign__menu__links__btns__btnLevelLanguage'
			}
			onClick={() => clickLevelLanguage(idLevelLanguage)}>
			{name}
		</button>
	)
}

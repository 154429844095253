import { NpcComposeCollapse } from './NpcComposeCollapse'
import { FormCoordinates } from './FormCoordinates'
import { ListMissions } from '../Missions/ListMissions'
import ModalContext from '../../../../context/editor/ModalContext'
import { useContext } from 'react'
import { FormChangeNPC } from '../ChangeNPC/FormChangeNPC'
import { useTranslation } from 'react-i18next'

export const NpcBodyCollapse = ({
	idScene,
	stateObjectNpc,
	setRefreshNpcsScene,
	setRefreshObjectNpc,
	statusGreeting,
	setStatusGreeting,
}) => {
	const { t } = useTranslation()

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	const changeNPC = () => {
		setTitleModal(
			t(
				'pages.editor.components.npc_body_collapse.change_npc_placeholder_alt'
			)
		)
		setContentModal(
			<FormChangeNPC
				idScene={idScene}
				stateObjectNpc={stateObjectNpc}
				setRefreshNpcsScene={setRefreshNpcsScene}
			/>
		)
		openModal(true)
	}

	return (
		<div className='npcSceneContent collapseStylesBody'>
			<div className='npcSceneContent__container'>
				<div className='npcSceneContent__container__Left'>
					<button className='editorBtnActionAdd' onClick={changeNPC}>
						{t(
							'pages.editor.components.npc_body_collapse.change_npc_placeholder_alt'
						)}
					</button>
					<a
						href={stateObjectNpc.silence_file}
						rel='noreferrer noopener'
						target='_blank'>
						<img
							src={stateObjectNpc.silence_file}
							alt={t(
								'pages.editor.components.npc_body_collapse.change_npc_placeholder_alt'
							)}
							loading='lazy'
						/>
					</a>
					<FormCoordinates
						itemNpc={stateObjectNpc}
						setRefreshNpcsScene={setRefreshNpcsScene}
					/>
				</div>

				<div className='npcSceneContent__container__Right'>
					{Object.keys(stateObjectNpc.composes).map((key) => (
						<NpcComposeCollapse
							key={stateObjectNpc.composes[key].id_npc_compose}
							itemCompose={stateObjectNpc.composes[key]}
							counterComposes={stateObjectNpc.counter_composes}
							idSubWorldSceneNpcAppearance={
								stateObjectNpc.id_sub_world_scene_npc_appearance
							}
							setRefreshNpcsScene={setRefreshNpcsScene}
							statusGreeting={statusGreeting}
							setStatusGreeting={setStatusGreeting}
						/>
					))}
				</div>

				<div className='npcSceneContent__container__Center'>
					<ListMissions
						idSubWorldSceneNpcAppearance={
							stateObjectNpc.id_sub_world_scene_npc_appearance
						}
						idScene={idScene}
						setRefreshObjectNpc={setRefreshObjectNpc}
						setRefreshNpcsScene={setRefreshNpcsScene}
					/>
				</div>
			</div>
		</div>
	)
}

import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../../context/editor/ModalContext'
import { FormOutfits } from './FormOutfits'
import { API_MANAGE_NPC_OUTFITS } from '../../../../../utils/constantsAdmin'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import AlertContext from '../../../../../context/editor/AlertContext'
import DeleteContext from '../../../../../context/editor/DeleteContext'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const NpcOutfitContent = ({ objectOutfit, setRefreshOutfitsNpc }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// SET OBJECT OUTFIT
	const [stateObjectOutfit, setStateObjectOutfit] = useState(undefined)
	useEffect(() => {
		setStateObjectOutfit(objectOutfit)
	}, [objectOutfit])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	const UpdateOutfits = async () => {
		setTitleModal(
			t('pages.editor.components.npc_outfit_content.update_suit')
		)
		setContentModal(
			<FormOutfits
				stateObjectOutfit={stateObjectOutfit}
				setStateObjectOutfit={setStateObjectOutfit}
				setRefreshOutfitsNpc={setRefreshOutfitsNpc}
			/>
		)
		openModal(true)
	}

	const DeleteOutfit = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_NPC_OUTFITS + objectOutfit.id_outfit_npc + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(t('common.cannot_delete'))
			showAlert()
		} else {
			setRefreshOutfitsNpc((prev) => !prev)
			openDelete()
		}
	}

	return (
		<>
			{stateObjectOutfit !== undefined ? (
				<div className='npcDataContent'>
					<div className='npcDataContent__data'>
						<div className='nameAndOptions'>
							<h1>{stateObjectOutfit.name}</h1>
							<div className='options'>
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(() => DeleteOutfit)
										setMessage(
											t(
												'pages.editor.components.npc_outfit_content.confirm_deleting_1'
											) +
												objectOutfit.name +
												'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />
									</p>
								</button>
								<button
									className='editorBtnActionEdit'
									onClick={UpdateOutfits}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							</div>
						</div>
						<div className='description'>
							<p>
								{stateObjectOutfit.description
									? stateObjectOutfit.description
									: t(
											'pages.editor.components.npc_outfit_content.without_description'
									  )}
							</p>
						</div>
					</div>
				</div>
			) : null}
		</>
	)
}

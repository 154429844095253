import React from 'react'
import { useTranslation } from 'react-i18next'

export const StoryTellerContentBodyCollapse = ({
	stateObjectStoryTellerContent,
}) => {
	const { t } = useTranslation()
	return (
		<div className='storyTellerContentTwoBody collapseStylesBody'>
			{stateObjectStoryTellerContent !== undefined ? (
				<>
					<div className='storyTellerContentTwoBody__container'>
						<div className='storyTellerContentTwoBody__container__Left'>
							<div className='info'>
								<h1>
									{t(
										'pages.editor.components.storyteller_content_body_collapse.audio'
									)}
								</h1>
								{stateObjectStoryTellerContent.audio_file ? (
									<audio
										src={
											stateObjectStoryTellerContent.audio_file
										}
										controls></audio>
								) : (
									<p>
										{t(
											'pages.editor.components.storyteller_content_body_collapse.none_audio_selected'
										)}
									</p>
								)}
							</div>
							<div className='info'>
								<h1>
									{t(
										'pages.editor.components.storyteller_content_body_collapse.none_audio_selected'
									)}
								</h1>
								{stateObjectStoryTellerContent.sound ? (
									<audio
										src={
											stateObjectStoryTellerContent.sound
												.audio_file
										}
										controls></audio>
								) : (
									<p>
										{t(
											'pages.editor.components.storyteller_content_body_collapse.none_audio_selected'
										)}
									</p>
								)}
							</div>
						</div>
						<div className='storyTellerContentTwoBody__container__Right'>
							<div className='info'>
								<h1>
									{t(
										'pages.editor.components.storyteller_content_body_collapse.character'
									)}
								</h1>
								<p>
									{stateObjectStoryTellerContent.type_character ===
									'n'
										? t(
												'pages.editor.components.storyteller_content_body_collapse.teller'
										  )
										: 'Teo Loro'}
								</p>
							</div>
							<div className='info'>
								<h1>
									{t(
										'pages.editor.components.storyteller_content_body_collapse.emotion'
									)}
								</h1>
								<p>{stateObjectStoryTellerContent.emotion}</p>
							</div>
							<div className='info'>
								<h1>
									{t(
										'pages.editor.components.storyteller_content_body_collapse.text'
									)}
								</h1>
								<p>{stateObjectStoryTellerContent.text}</p>
							</div>
							<div className='info'>
								<h1>
									{t(
										'pages.editor.components.storyteller_content_body_collapse.text_translated'
									)}
								</h1>
								<p>
									{
										stateObjectStoryTellerContent.text_translation
									}
								</p>
							</div>
						</div>
					</div>
				</>
			) : null}
		</div>
	)
}

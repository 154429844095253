import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../../context/editor/ModalContext'
import { FormPoses } from './FormPoses'
import { API_MANAGE_NPC_POSES } from '../../../../../utils/constantsAdmin'
import { SearchCamp } from '../../../searchAndPagination/SearchCamp'
import { NpcPosesContent } from './NpcPosesContent'
import { Pagination } from '../../../searchAndPagination/Pagination'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListNpcPoses = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// REFRESH POSES
	const [refreshPosesNpc, setRefreshPosesNpc] = useState(false)

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddPoses = () => {
		setTitleModal(t('pages.editor.components.list_npc_poses.add_pose'))
		setContentModal(<FormPoses setRefreshPosesNpc={setRefreshPosesNpc} />)
		openModal(true)
	}

	// DATA
	const [dataPoses, setDataPoses] = useState(undefined)

	// GET ALL POSES
	useEffect(() => {
		getData()
	}, [refreshPosesNpc])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme('get', API_MANAGE_NPC_POSES)
			setDataPoses(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	return (
		<div className='nav-content'>
			<div className='npcDataContent__btnAdd'>
				<h1>{t('pages.editor.components.list_npc_poses.poses')}</h1>
				<div className='boxBtnAdd'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddPoses}>
						{t('common.add')}
					</button>
				</div>
			</div>
			{dataPoses !== undefined ? (
				<>
					{dataPoses.results.length > 0 || dataPoses === 'search' ? (
						<>
							<SearchCamp
								setData={setDataPoses}
								setRefreshDataList={setRefreshPosesNpc}
								url={API_MANAGE_NPC_POSES}
								search={search}
								setSearch={setSearch}
							/>
							{dataPoses === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_npc_poses.none_pose_found'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t(
								'pages.editor.components.list_npc_poses.none_pose_loaded'
							)}
						</p>
					)}
					{dataPoses !== 'search' ? (
						<>
							{dataPoses.results.map((data_item, index) => (
								<NpcPosesContent
									key={data_item.id_npc_pose}
									objectPoses={data_item}
									setRefreshPosesNpc={setRefreshPosesNpc}
								/>
							))}
							<Pagination
								data={dataPoses}
								setData={setDataPoses}></Pagination>
						</>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}

import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormAnimations } from './FormAnimations'
import { API_MANAGE_ANIMATIONS } from '../../../../utils/constantsAdmin'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import CollapseAnimations from './CollapseAnimations'
import { Pagination } from '../../searchAndPagination/Pagination'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { LoadingIcon } from '../../LoadingIcon'
import { useTranslation } from 'react-i18next'

export const ListAnimations = () => {
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// REFRESH ANIMATIONS
	const [refreshAnimations, setRefreshAnimations] = useState(false)

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// DATA
	const [data, setData] = useState(undefined)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddAnimationsModal = () => {
		setTitleModal(t('pages.editor.components.list_animations.title'))
		setContentModal(
			<FormAnimations setRefreshAnimations={setRefreshAnimations} />
		)
		openModal(true)
	}
	const { t } = useTranslation()

	// GET ALL ANIMATIONS
	useEffect(() => {
		const getData = async () => {
			try {
				const result_data = await axiosSupreme(
					'get',
					API_MANAGE_ANIMATIONS
				)
				setData(result_data)
			} catch (e) {
				console.error('Error get Data: ', e)
			}
		}

		getData()
	}, [refreshAnimations])

	// ACTIVECOLLAPSE
	const [activeCollapseAnimations, setActiveCollapseAnimations] =
		useState(undefined)

	return (
		<>
			<div className='animations__container__btnAdd'>
				<div className='boxBtnAdd'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddAnimationsModal}>
						{t('common.add')}
					</button>
				</div>
			</div>
			{data !== undefined ? (
				<>
					{data === 'search' || data.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={setRefreshAnimations}
								url={API_MANAGE_ANIMATIONS}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_animations.no_animation'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t(
								'pages.editor.components.list_animations.no_registered_animation'
							)}
						</p>
					)}
					{data !== 'search' ? (
						<div className='adminDesign__container__mainContainerBank__Item__content__List'>
							{data.results.map((data_item, index) => (
								<CollapseAnimations
									key={data_item.id_animation}
									objectAnimation={data_item}
									setRefreshAnimations={setRefreshAnimations}
									activeCollapseAnimations={
										activeCollapseAnimations
									}
									setActiveCollapseAnimations={
										setActiveCollapseAnimations
									}
								/>
							))}
							<Pagination
								data={data}
								setData={setData}></Pagination>
						</div>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</>
	)
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_MANAGE_SOUNDS,
	API_MANAGE_STORY_TELLER_CONTENT,
} from '../../../../utils/constantsAdmin'

import SaveButton from '../../SaveButton'
import {
	onInputChangeDefault,
	onInputChangeSound,
	processErrors,
	returnProcessUrl,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'
import { useFetchOptions } from '../../../../hooks/useFetchOptions/useFetchOptions'

export const FormStoryTellerContent = ({
	id_storyteller,
	stateObjectStoryTellerContent,
	setStateObjectStoryTellerContent,
	setRefreshStoryTellerContents,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [emotion, setEmotion] = useState('')
	const [text, setText] = useState('')
	const [textTranslation, setTextTranslation] = useState('')
	const [order, setOrder] = useState('')
	const audioFile = useRef(null)
	const [sound, setSound] = useState('')
	const [character, setCharacter] = useState('n')

	// ERROR FOR FORM
	const [errorAudio, setErrorAudio] = useState('')

	const { fetchOptions } = useFetchOptions()

	// LIST VALUES
	const [listSounds, setListSounds] = useState({})
	useEffect(() => {
		const abortController = new AbortController()

		const updateData = (dataList) => {
			setListSounds((prevData) => ({
				...prevData,
				...dataList.reduce((acc, dataItem) => {
					acc[dataItem.id_sound] = {
						name: dataItem.name,
					}
					return acc
				}, {}),
			}))
		}

		fetchOptions(API_MANAGE_SOUNDS, updateData, abortController.signal)

		return () => {
			abortController.abort()
		}
	}, [])

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setEmotion('')
		setText('')
		setTextTranslation('')
		setOrder('')
		audioFile.current.value = null
	}

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// SET WORLD WHEN UPDATE
	useEffect(() => {
		if (stateObjectStoryTellerContent !== undefined) {
			setEmotion(stateObjectStoryTellerContent.emotion)
			setText(stateObjectStoryTellerContent.text)
			setTextTranslation(stateObjectStoryTellerContent.text_translation)
			setOrder(stateObjectStoryTellerContent.order)
			if (stateObjectStoryTellerContent.sound) {
				setSound(stateObjectStoryTellerContent.sound.id_sound)
			}
			setCharacter(
				stateObjectStoryTellerContent.type_character
					? stateObjectStoryTellerContent.type_character
					: 'n'
			)
		} else {
			clearFields()
		}
	}, [stateObjectStoryTellerContent])

	// SUBMIT FORM STORYTELLER CONTENT
	const onSubmitStoryTellerContent = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('emotion', event.target.elements.emotion.value)
		formData.append('text', event.target.elements.text.value)
		formData.append('sound', sound ? sound : '')
		formData.append('type_character', character)
		if (event.target.elements.audio_file.value) {
			formData.append(
				'audio_file',
				event.target.elements.audio_file.files[0]
			)
		}
		formData.append(
			'text_translation',
			event.target.elements.text_translation.value
		)
		formData.append('order', event.target.elements.order.value)
		formData.append('storyteller', id_storyteller)

		if (stateObjectStoryTellerContent === undefined) {
			// SAVE
			manageStoryTellerContent(
				API_MANAGE_STORY_TELLER_CONTENT,
				formData,
				'add'
			)
		} else {
			// UPDATE
			manageStoryTellerContent(
				API_MANAGE_STORY_TELLER_CONTENT +
					stateObjectStoryTellerContent.id_storyteller_content +
					'/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE DIALOGUE
	const manageStoryTellerContent = async (url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshStoryTellerContents((prev) => !prev)
			if (action === 'update') getNewObject((prev) => !prev)
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		let urlRequest = API_MANAGE_STORY_TELLER_CONTENT
		const result_data = await axiosSupreme(
			'get',
			(urlRequest +=
				stateObjectStoryTellerContent.id_storyteller_content + '/'),
			undefined
		)
		setStateObjectStoryTellerContent(result_data)
	}

	return (
		<>
			<form onSubmit={onSubmitStoryTellerContent}>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_storyteller_content.order'
						)}
					</label>
					<input
						name='order'
						className='admin__container__inputs__in'
						id='order'
						type='number'
						placeholder={t(
							'pages.editor.components.form_storyteller_content.order_placeholder'
						)}
						autoComplete='off'
						value={order}
						onChange={(event) =>
							onInputChangeDefault(event, setOrder)
						}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_storyteller_content.character'
						)}
					</label>
					<select
						maxLength='45'
						name='character'
						className='admin__container__inputs__in'
						id='character'
						type='text'
						placeholder={t(
							'pages.editor.components.form_storyteller_content.text_placeholder'
						)}
						autoComplete='off'
						value={character}
						onChange={(event) =>
							onInputChangeDefault(event, setCharacter)
						}
						required>
						<option value='n'>
							{t(
								'pages.editor.components.form_storyteller_content.teller'
							)}
						</option>
						<option value='p'>Teo Loro</option>
					</select>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_storyteller_content.emotion'
						)}
					</label>
					<select
						maxLength='45'
						name='emotion'
						className='admin__container__inputs__in'
						id='emotion'
						type='text'
						placeholder={t(
							'pages.editor.components.form_storyteller_content.text_placeholder'
						)}
						autoComplete='off'
						value={emotion}
						onChange={(event) =>
							onInputChangeDefault(event, setEmotion)
						}
						required>
						<option value=''>
							{t(
								'pages.editor.components.form_storyteller_content.select_emotion'
							)}
						</option>
						<option value='normal'>
							{t(
								'pages.editor.components.form_storyteller_content.normal'
							)}
						</option>
						<option value='felicidad'>
							{t(
								'pages.editor.components.form_storyteller_content.happiness'
							)}
						</option>
						<option value='tristeza'>
							{t(
								'pages.editor.components.form_storyteller_content.sadness'
							)}
						</option>
						<option value='sorpresa'>
							{t(
								'pages.editor.components.form_storyteller_content.surpriseness'
							)}
						</option>
					</select>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_storyteller_content.text'
						)}
					</label>
					<textarea
						maxLength='499'
						name='text'
						className='admin__container__inputs__in'
						id='text'
						type='text'
						placeholder={t(
							'pages.editor.components.form_storyteller_content.text_placeholder'
						)}
						autoComplete='off'
						value={text}
						onChange={(event) =>
							onInputChangeDefault(event, setText)
						}
						required></textarea>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_storyteller_content.text_translated_and_placeholder'
						)}
					</label>
					<textarea
						maxLength='499'
						name='text_translation'
						className='admin__container__inputs__in'
						id='text_translation'
						type='text'
						placeholder={t(
							'pages.editor.components.form_storyteller_content.text_translated_and_placeholder'
						)}
						autoComplete='off'
						value={textTranslation}
						onChange={(event) =>
							onInputChangeDefault(event, setTextTranslation)
						}
						required></textarea>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_storyteller_content.audio'
						)}
					</label>
					{stateObjectStoryTellerContent !== undefined ? (
						<>
							{stateObjectStoryTellerContent.audio_file !==
							null ? (
								<label htmlFor=''>
									{t('common.current_file')}
									<a
										href={
											stateObjectStoryTellerContent.audio_file
										}
										target='_blank'
										rel='noopener noreferrer'>
										{t('common.open')}
									</a>
								</label>
							) : null}
						</>
					) : null}
					<input
						name='audio_file'
						className='admin__container__inputs__in'
						id='audio_file'
						type='file'
						ref={audioFile}
						autoComplete='off'
						onChange={(event) =>
							onInputChangeSound(event, setErrorAudio, audioFile)
						}
						accept='audio/*'
						required={
							stateObjectStoryTellerContent === undefined
						}></input>
					{errorAudio && <p>{errorAudio}</p>}
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_storyteller_content.sound'
						)}
					</label>
					<select
						name='sound'
						className='admin__container__inputs__in'
						id='sound'
						placeholder={t(
							'pages.editor.components.form_storyteller_content.sound_placeholder'
						)}
						autoComplete='off'
						value={sound}
						onChange={(event) =>
							onInputChangeDefault(event, setSound)
						}>
						<option key='' value=''>
							{t(
								'pages.editor.components.form_storyteller_content.select_sound'
							)}
						</option>
						{Object.keys(listSounds).map((key) => (
							<option key={key} value={key}>
								{listSounds[key].name}
							</option>
						))}
					</select>
				</div>

				{stateLoading === false ? (
					<div className='admin__container__boxBtn'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</form>
		</>
	)
}

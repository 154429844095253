import React, { useContext, useEffect, useState } from 'react'
import AlertContext from '../../../../../context/editor/AlertContext'
import ModalContext from '../../../../../context/editor/ModalContext'
import { API_MANAGE_STORE_CATEGORY } from '../../../../../utils/constantsAdmin'

import SaveButton from '../../../SaveButton'
import {
	onInputChangeDefault,
	processErrors,
} from '../../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormCategories = ({
	stateObjectCategory,
	setStateObjectCategory,
	setRefreshCategories,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [usable, setUsable] = useState('')

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setDescription('')
		setUsable('')
	}

	// SET ANIMATION WHEN UPDATE
	useEffect(() => {
		if (stateObjectCategory !== undefined) {
			setName(stateObjectCategory.name)
			setDescription(stateObjectCategory.description)
			setUsable(stateObjectCategory.usable)
		} else {
			clearFields()
		}
	}, [stateObjectCategory])

	// SUBMIT FORM CATEGORY
	const onSubmitCategory = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		formData.append('description', event.target.elements.description.value)
		formData.append('usable', event.target.elements.usable.value)

		if (stateObjectCategory === undefined) {
			// SAVE
			manageAnimation(API_MANAGE_STORE_CATEGORY, formData, 'add')
		} else {
			// UPDATE
			manageAnimation(
				API_MANAGE_STORE_CATEGORY +
					stateObjectCategory.id_item_category +
					'/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE ANIMATION
	const manageAnimation = async (url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshCategories((prev) => !prev)
			if (action === 'update') setStateObjectCategory(result_data)
			setStateLoading(false)
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	return (
		<form onSubmit={onSubmitCategory}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_categories.name')}
				</label>
				<input
					maxLength='45'
					name='name'
					className='admin__container__inputs__in'
					id='name'
					type='text'
					placeholder={t(
						'pages.editor.components.form_categories.name_placeholder'
					)}
					autoComplete='off'
					value={name}
					onChange={(event) => onInputChangeDefault(event, setName)}
					required></input>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_categories.description_and_placeholder'
					)}
				</label>
				<textarea
					maxLength='500'
					name='description'
					className='admin__container__inputs__in'
					id='description'
					type='text'
					placeholder={t(
						'pages.editor.components.form_categories.description_and_placeholder'
					)}
					autoComplete='off'
					value={description}
					onChange={(event) =>
						onInputChangeDefault(event, setDescription)
					}></textarea>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_categories.usable_and_placeholder'
					)}
				</label>
				<select
					name='usable'
					className='admin__container__inputs__in'
					id='usable'
					placeholder={t(
						'pages.editor.components.form_categories.usable_and_placeholder'
					)}
					autoComplete='off'
					value={usable}
					onChange={(event) =>
						onInputChangeDefault(event, setUsable)
					}>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_categories.select_state'
						)}
					</option>
					<option value='true'>
						{t('pages.editor.components.form_categories.yes')}
					</option>
					<option value='false'>
						{t('pages.editor.components.form_categories.no')}
					</option>
				</select>
			</div>

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

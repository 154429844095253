import { useContext, useEffect, useRef, useState } from 'react'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'

import { API_MANAGE_SCENE_WORLDS } from '../../../../utils/constantsAdmin'
import ModalContext from '../../../../context/editor/ModalContext'
import FormSceneWorld from './FormSceneWorld'
import { useNavigate } from 'react-router-dom'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export default function SceneWorldComponent({
	idWorld,
	objectSceneWorld,
	setRefreshSceneWorlds,
}) {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// SET OBJECT SCENE
	const audioRef = useRef(null)
	const [stateObjectScene, setStateObjectScene] = useState(undefined)
	useEffect(() => {
		setStateObjectScene(objectSceneWorld)
	}, [objectSceneWorld])

	// NAVIGATE
	const navigate = useNavigate()

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE SCENE
	const UpdateSceneWorld = async () => {
		setTitleModal(
			t('pages.editor.components.scene_world_component.update_scene')
		)
		setContentModal(
			<FormSceneWorld
				idWorld={idWorld}
				stateObjectScene={stateObjectScene}
				setStateObjectScene={setStateObjectScene}
				setRefreshSceneWorlds={setRefreshSceneWorlds}
			/>
		)
		openModal(true)
	}

	// DELETE SCENE
	const DeleteSceneWorld = async (idWorld) => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_SCENE_WORLDS + stateObjectScene.id_world_scene + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(t('common.cannot_delete'))
			showAlert()
		} else {
			setRefreshSceneWorlds((prev) => !prev)
			openDelete()
		}
	}

	// OPEN JSON
	const OpenFileColision = () => {
		window.open(stateObjectScene.collision_file, '_blank').focus()
	}

	// REDIRECT EDIT
	const reDirectEdit = (action) => {
		localStorage.setItem('TYPE_CONTEXT_SPACE', 'World')
		localStorage.setItem('ID_SCENE', stateObjectScene.id_world_scene)
		localStorage.setItem('ITEM_DESIGNER', action)
		navigate('/manage-scene-world')
	}

	return (
		<div className='worldsBodyComponent__Content__ContainerSceneWorld__ListSceneWorld__Item'>
			{stateObjectScene != null && (
				<div className='sceneWorlds'>
					<div
						className='sceneWorlds__background'
						style={{
							backgroundColor: stateObjectScene.background_color,
						}}>
						<a
							rel='noreferrer noopener'
							href={stateObjectScene.image_file}
							target='_blank'>
							<img
								src={stateObjectScene.image_file}
								alt={t(
									'pages.editor.components.scene_world_component.alt_world'
								)}
							/>
						</a>
						<p>{stateObjectScene.background_color}</p>
					</div>
					<div className='sceneWorlds__data'>
						<div className='sceneWorlds__data__name'>
							<div className='sceneWorlds__data__name__title'>
								<h2 className=''>{stateObjectScene.name}</h2>
							</div>
							<div className='sceneWorlds__data__name__options'>
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(
											() => DeleteSceneWorld
										)
										setMessage(
											t(
												'pages.editor.components.scene_world_component.confirm_deleting_1'
											) +
												stateObjectScene.name +
												'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />{' '}
									</p>
								</button>
								<button
									className='editorBtnActionEdit'
									onClick={UpdateSceneWorld}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							</div>
						</div>

						<div className='sceneWorlds__data__soundandcollision'>
							<div className='sceneWorlds__data__soundandcollision__sound'>
								<div className='sceneWorlds__data__soundandcollision__sound__title'>
									<h2 className=''>
										{t(
											'pages.editor.components.scene_world_component.instrumental_sound'
										)}
									</h2>
								</div>
								<div className='sceneWorlds__data__soundandcollision__sound__player'>
									{stateObjectScene.sound ? (
										<audio
											ref={audioRef}
											controls
											src={
												stateObjectScene.sound
													.audio_file
											}
										/>
									) : (
										<p>
											{t(
												'pages.editor.components.scene_world_component.none'
											)}
										</p>
									)}
								</div>
							</div>

							<div className='sceneWorlds__data__soundandcollision__sound'>
								<div className='sceneWorlds__data__soundandcollision__sound__title'>
									<h2 className=''>
										{t(
											'pages.editor.components.scene_world_component.ambient_sound'
										)}
									</h2>
								</div>
								<div className='sceneWorlds__data__soundandcollision__sound__player'>
									{stateObjectScene.ambient_sound ? (
										<audio
											ref={audioRef}
											controls
											src={
												stateObjectScene.ambient_sound
													.audio_file
											}
										/>
									) : (
										<p>
											{t(
												'pages.editor.components.scene_world_component.none'
											)}
										</p>
									)}
								</div>
							</div>

							<div className='sceneWorlds__data__soundandcollision__collision'>
								<div className='sceneWorlds__data__soundandcollision__collision__title'>
									<h2 className=''>
										{t(
											'pages.editor.components.scene_world_component.collition_file'
										)}
									</h2>
								</div>
								<div className='sceneWorlds__data__soundandcollision__collision__collisionfile'>
									{stateObjectScene.collision_file ? (
										<button
											className='editorBtnActionAdd'
											onClick={OpenFileColision}>
											{t('common.open')}
										</button>
									) : (
										<p>
											{t(
												'pages.editor.components.scene_world_component.none'
											)}
										</p>
									)}
								</div>
							</div>
						</div>

						<div className='sceneWorlds__data__edition'>
							<div className='sceneWorlds__data__edition__title'>
								<h2 className=''>
									{t(
										'pages.editor.components.scene_world_component.edit'
									)}
								</h2>
							</div>
							<div className='sceneWorlds__data__edition__contentButtons'>
								<button
									className='editorBtnActionAdd'
									onClick={() => reDirectEdit('Decorations')}>
									{t(
										'pages.editor.components.scene_world_component.decorations'
									)}
								</button>
								<button
									className='editorBtnActionAdd'
									onClick={() => reDirectEdit('Doors')}>
									{t(
										'pages.editor.components.scene_world_component.doors'
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

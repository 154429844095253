import { BASE_API } from '../urls'

export const urlWithCorrectProtocol = (url) => {
	return url.startsWith('https') ? url : url.replace('http', 'https')
}

export const getRealUrl = (url = '', domain = null) => {
	const domainSet = domain || BASE_API
	return url?.startsWith('https') ? url : `${domainSet}${url}`
}

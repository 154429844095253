import { useEffect, useRef, useState } from 'react'
import volumeOnIcon from '../../assets/icons/volumeOn.png'

import '../../styles/AudioHelpButton.scss'
import { useSceneConfig } from '../../context/SceneConfigProvider'

const AudioHelpButton = ({
	src,
	style = {},
	styleChild = {},
	playingOtherAudio,
}) => {
	const { soundPointers } = useSceneConfig()
	const [isValidSource, setIsValidSource] = useState(false)
	const audio = useRef(new Audio())

	useEffect(() => {
		if (src) {
			try {
				new URL(src)
			} catch (error) {
				setIsValidSource(false)
				return
			}
			audio.current.src = src
			audio.current.addEventListener(
				'loadeddata',
				() => {
					setIsValidSource(true)
				},
				{ once: true }
			)
		}

		return () => {
			if (audio.current) audio.current?.pause()
		}
	}, [src])

	/**
	 * Perfect, now, every game, make a sound play once they are right.
	 * So I need to do exactly this, but different.
	 */

	const playAudio = () => {
		const { volume } = soundPointers.voices
		if (isValidSource && volume !== '0' && !playingOtherAudio) {
			audio.current.volume = Number.isNaN(Number(volume))
				? 1
				: Number(volume)
			audio.current.play()
		}
	}

	return (
		<div className='audio__help__button' style={style}>
			<button
				className='audio__help__button__container'
				style={styleChild}
				disabled={!isValidSource}
				onClick={playAudio}>
				<img
					src={volumeOnIcon}
					className='audio__help__button__container__image'
					alt='icon play audio'
					loading='lazy'
					decoding='async'
				/>
			</button>
		</div>
	)
}

export default AudioHelpButton

import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../context/editor/ModalContext'
import FormSubWorld from './FormSubWorld'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import SubWorldComponent from './SubWorldComponent'
import { Pagination } from '../../searchAndPagination/Pagination'
import { GET_SUB_WORLD_BY_WORLD } from '../../../../utils/constantsAdmin'
import { LoadingIcon } from '../../LoadingIcon'
import { ManageStorageSubworld } from '../../../../utils/manage_local_storage'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListSubWorlds = ({ idWorld }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { getSubworld } = ManageStorageSubworld()

	// REFRESH SUBWORLDS
	const [refreshSubWorlds, setRefreshSubWorlds] = useState(false)

	// DATA
	const [dataSubWorlds, setDataSubWorlds] = useState(undefined)

	// GET SUBWORLDS FOR LEVEL WORLDS
	useEffect(() => {
		getData()
	}, [refreshSubWorlds])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				GET_SUB_WORLD_BY_WORLD + idWorld + '/'
			)
			setDataSubWorlds(result_data)
			setHistory()
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// HISTORY SUBWORLD
	const setHistory = () => {
		if (getSubworld() && getSubworld() !== 'YES') {
			setActiveCollapseSubWorld(getSubworld())
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddSubWorldModal = () => {
		setTitleModal(t('pages.editor.components.list_subworlds.add_subworld'))
		setContentModal(
			<FormSubWorld
				idWorld={idWorld}
				setRefreshSubWorlds={setRefreshSubWorlds}
			/>
		)
		openModal(true)
	}

	// ACTIVE COLLAPSE WORLD
	const [activeCollapseSubWorld, setActiveCollapseSubWorld] =
		useState(undefined)

	return (
		<div className='worldsBodyComponent__Content__ContainerSubWorld'>
			<div className='worldsBodyComponent__Content__ContainerSubWorld__ContainerTitle'>
				<h1>{t('pages.editor.components.list_subworlds.subworlds')}</h1>
				<div className='worldsBodyComponent__Content__ContainerSubWorld__ContainerTitle__BoxButton'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddSubWorldModal}>
						{t('common.add')}
					</button>
				</div>
			</div>

			{dataSubWorlds !== undefined ? (
				<>
					{dataSubWorlds === 'search' ||
					dataSubWorlds.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setDataSubWorlds}
								setRefreshDataList={setRefreshSubWorlds}
								url={GET_SUB_WORLD_BY_WORLD + idWorld + '/'}
								search={search}
								setSearch={setSearch}
							/>
							{dataSubWorlds === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_subworlds.none_subworld_found'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t(
								'pages.editor.components.list_subworlds.none_subworld_assigned'
							)}
						</p>
					)}
					{dataSubWorlds !== 'search' ? (
						<div className='worldsBodyComponent__Content__ContainerSubWorld__ListSubWorld'>
							{dataSubWorlds.results.map((data_item) => (
								<SubWorldComponent
									key={data_item.id_sub_world}
									idWorld={idWorld}
									objectSubworld={data_item}
									setRefreshSubWorlds={setRefreshSubWorlds}
									activeCollapseSubWorld={
										activeCollapseSubWorld
									}
									setActiveCollapseSubWorld={
										setActiveCollapseSubWorld
									}
								/>
							))}
							<Pagination
								data={dataSubWorlds}
								setData={setDataSubWorlds}></Pagination>
						</div>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}

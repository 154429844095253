import { useEffect, useState } from 'react'
import { ListSceneWorld } from '../ScenesWorlds/ListSceneWorld'
import { ListSubWorlds } from '../SubWorlds/ListSubWorlds'
import {
	ManageStorageSubworld,
	ManageStorageWorldScene,
} from '../../../../utils/manage_local_storage'
import { FaQuestion } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

export default function BodyCollapseWorld({
	idLevelLanguage,
	stateObjectWorld,
}) {
	const { t } = useTranslation()

	// LOCAL STORAGE
	const { setWorldScene, getWorldScene } = ManageStorageWorldScene()
	const { setSubworld, getSubworld } = ManageStorageSubworld()

	//BUTTONS SCENE OR SUBWORLDS
	const [scene, setScene] = useState(false)
	const [subWorlds, setSubWorlds] = useState(false)

	// SHOW CONTENT
	const showContent = (setFunction) => {
		setSubWorlds(false)
		setScene(false)
		setFunction(true)
		if (setFunction === setScene) {
			setWorldScene('YES')
		} else if (setFunction === setSubWorlds) {
			setSubworld('YES')
		}
	}

	useEffect(() => {
		if (getWorldScene()) {
			setScene(true)
		} else if (getSubworld()) {
			setSubWorlds(true)
		}
	}, [])

	// OPEN VIDEO
	// const OpenVideo = (url_video) => {
	// 	setTitleModal(t('pages.editor.components.body_collapse_worlds.video'))
	// 	setContentModal(
	// 		<div className='videoWorld'>
	// 			<video controls autoPlay>
	// 				<source src={url_video} />
	// 			</video>
	// 		</div>
	// 	)
	// 	openModal(true)
	// }

	return (
		<div className='worldsBodyComponent collapseStylesBody'>
			<div className='worldsBodyComponent__Left'>
				<h3>
					{t(
						'pages.editor.components.body_collapse_worlds.star_select'
					)}
				</h3>
				{stateObjectWorld.star ? (
					<a
						className='worldsBodyComponent__Left__Image'
						href={stateObjectWorld.star.image_file}
						rel='noreferrer noopener'
						target='_blank'>
						<img
							src={stateObjectWorld.star.image_file}
							alt={t(
								'pages.editor.components.body_collapse_worlds.alt_star'
							)}
						/>
					</a>
				) : (
					<div className='worldsBodyComponent__Left__IconQuestion'>
						<label>
							<FaQuestion />
						</label>
					</div>
				)}
			</div>

			<div className='worldsBodyComponent__Right'>
				<button
					className={
						scene
							? 'editorBtnActionAddActive'
							: 'editorBtnActionAdd'
					}
					onClick={() => showContent(setScene)}>
					{t(
						'pages.editor.components.body_collapse_worlds.scene_maps_world'
					)}
				</button>

				<button
					className={
						subWorlds
							? 'editorBtnActionAddActive'
							: 'editorBtnActionAdd'
					}
					onClick={() => showContent(setSubWorlds)}>
					{t(
						'pages.editor.components.body_collapse_worlds.subworlds'
					)}
				</button>
			</div>

			<div className='worldsBodyComponent__Content'>
				{scene && (
					<ListSceneWorld idWorld={stateObjectWorld.id_world} />
				)}
				{subWorlds && (
					<ListSubWorlds idWorld={stateObjectWorld.id_world} />
				)}
			</div>
		</div>
	)
}

import { useTranslation } from 'react-i18next'
import { ListNpcs } from './NPCs/ListNpcs'
import { NpcStateCollapse } from './NpcStateCollapse'

export const NpcsContent = () => {
	const { t } = useTranslation()

	return (
		<div className='adminDesign__container__mainContainerBank'>
			<div className='adminDesign__container__mainContainerBank__Item'>
				<div className='adminDesign__container__mainContainerBank__Item__title'>
					<h1>
						{t('pages.editor.components.npcs_content.bank_npcs')}
					</h1>
				</div>
				<div className='adminDesign__container__mainContainerBank__Item__content'>
					<hr />
					<div className='containerNpcState'>
						<NpcStateCollapse />
					</div>
					<ListNpcs />
				</div>
			</div>
		</div>
	)
}

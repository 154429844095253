import { useAuthProvider } from '../../context/AuthProvider/AuthProvider'
import { returnProcessUrl } from '../../utils/general_functions_forms'

export const useFetchOptions = () => {
	const { axiosSupreme } = useAuthProvider()

	const fetchOptions = async (url, updateDataList, signal) => {
		const processedUrl = returnProcessUrl(url)
		try {
			const resultData = await axiosSupreme(
				'get',
				processedUrl,
				undefined,
				signal
			)

			updateDataList(resultData.results)

			if (resultData.next) {
				fetchOptions(resultData.next, updateDataList, signal)
			}
		} catch {}
	}

	return {
		fetchOptions,
	}
}

function newGame(Phaser, preload, create, update, sceneData) {
	/**
	 * @typedef {Object} game The Phaser game and all of its functionalities.
	 * @property {String|Number} type the type of the canvas.
	 * @property {Number} width the with of the canvas in pixels.
	 * @property {Number} height the height of the canvas in pixels.
	 * @property {String|Hexadecimal} backgroundColor the color of the canvas.
	 * @property {Object} physics the config of the physics in the game.
	 * @property {Object} dom allows the create of html inside of the canvas. (NPC nameTag)
	 * @property {Object} scene specifies the functions that phaser is going to execute as
	 * preload, create and update.
	 * @property {DomElement} parent the parent of the canvas, in this case this component (scene)
	 */
	let game = null

	game = new Phaser.Game({
		type: Phaser.AUTO,
		width: window.innerWidth,
		height: window.innerHeight,
		backgroundColor: sceneData.backgroundColor,
		physics: {
			default: 'matter',
			matter: {
				gravity: { y: 0 },
				debug: false,
			},
		},
		fps: { limit: 20 },
		dom: { createContainer: true },
		scene: { preload, create, update },
		audio: {
			disableWebAudio: false, // Usa el sistema Web Audio de Phaser
		},
		parent: document.getElementById('scene'),
	})
	return game
}

function destroyGame(game) {
	if (game) game.destroy(true)
}

export { newGame, destroyGame }

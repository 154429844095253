import { ListGames } from './Game/ListGames'
import { ListChallenges } from './Challenge/ListChallenges'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ChallengeAndGameCollapse = () => {
	const { t } = useTranslation()

	const [activeTab, setActiveTab] = useState('challenges')
	const handleTabClick = (tabName) => {
		setActiveTab(tabName)
	}

	return (
		<div className='challengeAndGameColl__container'>
			<div className='challengeAndGameContent'>
				<div className='challengeAndGameContent__container'>
					<nav>
						<ul>
							<li
								className={`nav-item ${
									activeTab === 'challenges' ? 'active' : ''
								}`}
								onClick={() => handleTabClick('challenges')}>
								<span className='nav-link'>
									{t(
										'pages.editor.components.challenge_game_collapse.challenge'
									)}
								</span>
							</li>
							<li
								className={`nav-item ${
									activeTab === 'games' ? 'active' : ''
								}`}
								onClick={() => handleTabClick('games')}>
								<span className='nav-link'>
									{t(
										'pages.editor.components.challenge_game_collapse.games'
									)}
								</span>
							</li>
						</ul>
					</nav>
					<div>
						{activeTab === 'challenges' && (
							<div className='nav-content'>
								<ListChallenges />
							</div>
						)}
						{activeTab === 'games' && (
							<div className='nav-content'>
								<ListGames />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../context/editor/ModalContext'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { Pagination } from '../../searchAndPagination/Pagination'
import {
	GET_STORY_TELLER_BY_SCENE,
	GET_STORY_TELLER_BY_SCENE_SUB_WORLD,
} from '../../../../utils/constantsAdmin'
import { CollapseStoryTeller } from './CollapseStoryTeller'
import { FormStoryTeller } from './FormStoryTeller'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { LoadingIcon } from '../../LoadingIcon'
import { FaVolumeUp } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { API_RESET_STORYTELLER_PROFILE } from '../../../../utils/constantsAdmin'
import AlertContext from '../../../../context/editor/AlertContext'
import { useTranslation } from 'react-i18next'

export const ListStoryTeller = ({ idScene }) => {
	const navigate = useNavigate()

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	const { t } = useTranslation()

	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	const { gettypeContextSpace } = ManageStorageAdminDesigner()

	//REFRES STORY TELLER
	const [refreshStorysTeller, setRefreshStorysTeller] = useState(false)

	//VALUES SEARCH
	const [search, setSearch] = useState('')

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// DATA
	const [dataStoryTeller, setDataStoryTeller] = useState(undefined)

	// GET ALL STORYS TELLERS
	useEffect(() => {
		getData()
	}, [refreshStorysTeller])

	const getData = async () => {
		let url =
			(gettypeContextSpace() === 'World'
				? GET_STORY_TELLER_BY_SCENE
				: GET_STORY_TELLER_BY_SCENE_SUB_WORLD) +
			idScene +
			'/'
		try {
			const result_data = await axiosSupreme('get', url)
			setDataStoryTeller(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	//ADD STORY TELLERS
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddStoryTellerModal = () => {
		setTitleModal(
			t('pages.editor.components.list_storyteller.add_storyteller')
		)
		setContentModal(
			<FormStoryTeller
				idScene={idScene}
				setRefreshStorysTeller={setRefreshStorysTeller}
			/>
		)
		openModal(true)
	}

	// ACTIVECOLLAPSE
	const [activeCollapseStoryTeller, setActiveCollapseStoryTeller] =
		useState(undefined)

	// DELETE SOUND
	const deleteStoryTellerProfiles = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_RESET_STORYTELLER_PROFILE + idScene + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(t('common.cannot_delete'))
			showAlert()
		} else {
			openDelete()
			setTypeMessage('success')
			setMessageAlert(t('success.correct_deletion'))
			showAlert()
		}
	}

	return (
		<div className='storyTellerSceneWorld'>
			<div className='storyTellerSceneWorld__btnNpcs'>
				<button
					className='editorBtnActionBankActive'
					onClick={() => navigate('/manage-sound')}>
					{' '}
					<p className='pIcons'>
						<FaVolumeUp />{' '}
					</p>{' '}
					{t('pages.editor.components.list_storyteller.sounds')}{' '}
				</button>
			</div>

			<div className='storyTellerSceneWorld__btnAdd'>
				<h1>
					{t(
						'pages.editor.components.list_storyteller.scene_storyteller'
					)}
				</h1>
				<div className='boxBtnAdd'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddStoryTellerModal}>
						{t('common.add')}
					</button>
				</div>
			</div>

			<div className='storyTellerSceneWorld__content'>
				{dataStoryTeller !== undefined ? (
					<>
						{dataStoryTeller === 'search' ||
						dataStoryTeller.results.length > 0 ? (
							<>
								<SearchCamp
									setData={setDataStoryTeller}
									setRefreshDataList={setRefreshStorysTeller}
									url={
										gettypeContextSpace() === 'World'
											? GET_STORY_TELLER_BY_SCENE +
											  idScene +
											  '/'
											: GET_STORY_TELLER_BY_SCENE_SUB_WORLD +
											  idScene +
											  '/'
									}
									search={search}
									setSearch={setSearch}
								/>
								{dataStoryTeller === 'search' ? (
									<p className='noSelect'>
										{t(
											'pages.editor.components.list_storyteller.none_storyteller_found'
										)}
									</p>
								) : null}
							</>
						) : (
							<p className='noSelect'>
								{t(
									'pages.editor.components.list_storyteller.none_storyteller_created'
								)}
							</p>
						)}
						{dataStoryTeller !== 'search' ? (
							<div className='storyTellerSceneWorld__content__listStories'>
								{dataStoryTeller.results.map(
									(data_item, index) => (
										<CollapseStoryTeller
											key={
												data_item.hasOwnProperty(
													'id_storyteller'
												)
													? data_item.id_storyteller
													: data_item.id_storyteller
											}
											idScene={idScene}
											objectStoryTeller={data_item}
											setRefreshStorysTeller={
												setRefreshStorysTeller
											}
											activeCollapseStoryTeller={
												activeCollapseStoryTeller
											}
											setActiveCollapseStoryTeller={
												setActiveCollapseStoryTeller
											}></CollapseStoryTeller>
									)
								)}
								<Pagination
									data={dataStoryTeller}
									setData={setDataStoryTeller}></Pagination>
							</div>
						) : null}
					</>
				) : (
					<LoadingIcon />
				)}
			</div>

			<br />

			<div className='storyTellerSceneWorld__btnNpcs'>
				<button
					className='editorBtnActionDeleteWithoutIcon'
					onClick={() => {
						setFunctionDelete(() => deleteStoryTellerProfiles)
						setMessage(
							t(
								'pages.editor.components.list_storyteller.confirm_deleting_1'
							)
						)
						openDelete()
					}}>
					{t(
						'pages.editor.components.list_storyteller.reset_visit_storyteller'
					)}
				</button>
			</div>
		</div>
	)
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import SaveButton from '../../SaveButton'
import { LoadingIcon } from '../../LoadingIcon'
import {
	API_CHANGE_NPC_SCENE,
	API_MANAGE_NPC,
	GET_NPC_COMPOSE_BY_NPC,
} from '../../../../utils/constantsAdmin'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { useTranslation } from 'react-i18next'
import { useFetchOptions } from '../../../../hooks/useFetchOptions/useFetchOptions'

export const FormChangeNPC = ({
	idScene,
	stateObjectNpc,
	setRefreshNpcsScene,
}) => {
	const { t } = useTranslation()
	// AXIOS
	const { axiosSupreme } = useAuthProvider()

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [npc, setNpc] = useState(undefined)
	const [stateNpc, setStateNpc] = useState(undefined)
	const [imageCompose, setImageCompose] = useState(undefined)
	const [errorComposeScene, setErrorComposeScene] = useState(false)

	const onSubmitFormChange = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		let values_composes_scene = Object.values(stateObjectNpc.composes).map(
			(item) => item.id_npc_compose_sub_world_scene_npc_appearance
		)
		let formDataNpcChange = new FormData()
		formDataNpcChange.append('compose_selected', stateNpc)
		formDataNpcChange.append('list_composes', values_composes_scene)

		try {
			await axiosSupreme(
				'patch',
				API_CHANGE_NPC_SCENE + idScene + '/',
				formDataNpcChange
			)
			setRefreshNpcsScene((prev) => !prev)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(errorPromise.response.data.error)
			showAlert()
		}
		setStateLoading(false)
		openModal(true)
	}

	// VALUES LIST FOR SELECTS
	const [listNpc, setListNpc] = useState({})
	const [listStateNpc, setListStateNpc] = useState({})

	const abortController = useRef()

	const { fetchOptions } = useFetchOptions()

	// GET ALL NPC
	useEffect(() => {
		const updateDataList = (dataList) => {
			setListNpc((prevData) => ({
				...prevData,
				...dataList.reduce((acc, dataItem) => {
					acc[dataItem.id_npc] = {
						name: dataItem.name,
						silence_file: dataItem.silence_file,
					}
					return acc
				}, {}),
			}))
		}

		const abortController = new AbortController()
		fetchOptions(API_MANAGE_NPC, updateDataList, abortController.signal)

		return () => {
			abortController.abort()
		}
	}, [])

	const onChangeNpc = async (value, setFunction) => {
		setStateNpc(undefined)
		setListStateNpc({})
		setImageCompose(undefined)
		setErrorComposeScene(false)
		if (value === '') {
			setFunction(undefined)
		} else {
			setFunction(value)

			if (abortController.current instanceof AbortController)
				abortController.current.abort()
			abortController.current = new AbortController()

			const updateDataList = (dataList) => {
				setListStateNpc((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_npc_compose] = {
							name: dataItem.name,
							image_file: dataItem.image_file,
						}
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				`${GET_NPC_COMPOSE_BY_NPC}${value}/`,
				updateDataList,
				abortController.current.signal
			)
		}
	}

	const onInputChangeState = async (event, setFunction) => {
		setFunction(event.target.value)
	}

	return (
		<form onSubmit={onSubmitFormChange}>
			<div className='admin__container__inputs'>
				{' '}
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_change_npc.current_npc')}{' '}
					{stateObjectNpc.name}
				</label>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_change_npc.available_npc')}
				</label>
				<select
					name='npc'
					className='admin__container__inputs__in'
					id='npc'
					placeholder={t(
						'pages.editor.components.form_change_npc.placeholder_stars'
					)}
					autoComplete='off'
					required
					value={npc}
					onChange={(event) =>
						onChangeNpc(event.target.value, setNpc)
					}>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_change_npc.select_npc'
						)}
					</option>
					{Object.keys(listNpc).map((key) => (
						<option key={key} value={key}>
							{listNpc[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_change_npc.configuration_npc'
					)}
				</label>
				<select
					name='stateNpc'
					className='admin__container__inputs__in'
					id='stateNpc'
					placeholder={t(
						'pages.editor.components.form_change_npc.placeholder_stars'
					)}
					autoComplete='off'
					required
					value={stateNpc}
					onChange={(event) =>
						onInputChangeState(event, setStateNpc)
					}>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_change_npc.select_configuration_npc'
						)}
					</option>
					{Object.keys(listStateNpc).map((key) => (
						<option key={key} value={key}>
							{listStateNpc[key].name}
						</option>
					))}
				</select>
				{imageCompose !== undefined ? (
					<div className='admin__container__img'>
						<img
							src={imageCompose}
							alt={t(
								'pages.editor.components.form_change_npc.alt_admin'
							)}
							loading='lazy'
						/>
					</div>
				) : null}
			</div>

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

import { useContext, useEffect, useRef, useState } from 'react'
import {
	API_CONTENT_MISSION,
	API_CONTENT_MISSION_ADD_ONS,
	API_MANAGE_LIST_LEVELS_LANGUAGES,
	GET_SCENE_SUBWORLD_BY_SUBWORLD,
	GET_SUB_WORLD_BY_WORLD,
	GET_WORLDS_BY_LEVEL,
} from '../../../../utils/constantsAdmin'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	handleKeyPressInteger,
	onInputChangeDefault,
	onInputChangeImage,
	onInputChangeSound,
	processErrors,
} from '../../../../utils/general_functions_forms'
import SaveButton from '../../SaveButton'
import { LoadingIcon } from '../../LoadingIcon'
import { ManageFile } from '../ManageFile'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'
import { ManageStorageGameLanguage } from '../../../../utils/manage_local_storage'
import { useFetchOptions } from '../../../../hooks/useFetchOptions/useFetchOptions'

export const FormContentMission = ({
	idObjectMission,
	objectContentMission,
	setStateObjectContentMission,
	setRefreshMissionsContent,
	idScene,
	typeMission,
}) => {
	const { t } = useTranslation()

	// LOCAL STORAGE
	const { getGameLanguage } = ManageStorageGameLanguage()

	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// VALUES FORM
	const [order, setOrder] = useState('')
	const [text, setText] = useState('')
	const [textTranslation, setTextTranslation] = useState('')
	const audioFile = useRef(null)
	const ImageFile = useRef(null)
	const [frameSizeX, setFrameSizeX] = useState('')
	const [frameSizeY, setFrameSizeY] = useState('')
	const [framesAmount, setFramesAmount] = useState('')
	const [coordinatesXPosition, setCoordinatesXPosition] = useState('')
	const [coordinatesYPosition, setCoordinatesYPosition] = useState('')
	const ObjectImageFile = useRef(null)
	// DATA FOR LOST OBJECT
	const [levelLanguage, setLevelLanguage] = useState('')
	const [world, setWorld] = useState('')
	const [subWorld, setSubWorld] = useState('')
	const [sceneSubWorld, setSceneSubWorld] = useState('')

	// ERROR FOR FORM
	const [errorAudio, setErrorAudio] = useState('')
	const [errorImageFile, setErrorImageFile] = useState('')
	const [errorObjectImageFile, setErrorObjectImageFile] = useState('')

	// FOCUS
	const firstInputRef = useRef(null)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setOrder('')
		setText('')
		setTextTranslation('')
		setErrorImageFile('')
		setFrameSizeX('')
		setFrameSizeY('')
		setFramesAmount('')
		setCoordinatesXPosition('')
		setCoordinatesYPosition('')
		setLevelLanguage('')
		setWorld('')
		setSubWorld('')
		setSceneSubWorld('')
		if (typeMission === 'o') {
			ObjectImageFile.current.value = null
		} else if (typeMission !== 'o') {
			audioFile.current.value = null
		}
	}

	// SET MISSION WHEN UPDATE
	useEffect(() => {
		if (objectContentMission !== undefined) {
			setOrder(objectContentMission.mission_content.order)
			setText(objectContentMission.mission_content.text)
			setTextTranslation(
				objectContentMission.mission_content.text_translation
			)
			if (objectContentMission.frame_size) {
				setFrameSizeX(
					objectContentMission.frame_size
						.replace('[', '')
						.replace(']', '')
						.split(',')[0]
				)
				setFrameSizeY(
					objectContentMission.frame_size
						.replace('[', '')
						.replace(']', '')
						.split(',')[1]
				)
			}
			if (objectContentMission.frames_amount) {
				setFramesAmount(objectContentMission.frames_amount)
			}
			if (objectContentMission.coordinates) {
				setCoordinatesXPosition(
					objectContentMission.coordinates
						.replace('[', '')
						.replace(']', '')
						.split(',')[0]
				)
				setCoordinatesYPosition(
					objectContentMission.coordinates
						.replace('[', '')
						.replace(']', '')
						.split(',')[1]
				)
			}
			if (objectContentMission.sub_world_scene) {
				setDataSceneSubWorld()
			}
		} else {
			clearFields()
		}
	}, [objectContentMission])

	useEffect(() => {
		firstInputRef.current.focus()
	}, [])

	const setDataSceneSubWorld = () => {
		onChangeLevelLanguage(
			objectContentMission.sub_world_scene.sub_world.world.level_language
				.id_level_language
		)
		onChangeWorld(
			objectContentMission.sub_world_scene.sub_world.world.id_world
		)
		onChangeSubWorld(
			objectContentMission.sub_world_scene.sub_world.id_sub_world
		)
		setSceneSubWorld(
			objectContentMission.sub_world_scene.id_scene_sub_world
		)
	}

	const onSubmitForm = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formDataMissionContent = new FormData()
		formDataMissionContent.append('text', text)
		formDataMissionContent.append('text_translation', textTranslation)
		formDataMissionContent.append('order', order)
		formDataMissionContent.append('mission', idObjectMission)

		let formDataMissionContentAddOns = new FormData()
		if (typeMission !== 'o') {
			if (event.target.elements.audio_file.value) {
				formDataMissionContentAddOns.append(
					'audio_file',
					event.target.elements.audio_file.files[0]
				)
			}
			if (event.target.elements.image_reference_file.value) {
				formDataMissionContentAddOns.append(
					'image_reference_file',
					event.target.elements.image_reference_file.files[0]
				)
			}
		}
		if (typeMission === 'o') {
			if (
				event.target.elements.frameSizeX.value &&
				event.target.elements.frameSizeY.value
			) {
				formDataMissionContentAddOns.append(
					'frame_size',
					'[' + frameSizeX + ',' + frameSizeY + ']'
				)
			}

			if (event.target.elements.frames_amount.value) {
				formDataMissionContentAddOns.append(
					'frames_amount',
					framesAmount
				)
			}

			if (
				event.target.elements.coordinatesXPosition.value &&
				event.target.elements.coordinatesYPosition.value
			) {
				formDataMissionContentAddOns.append(
					'coordinates',
					'[' +
						coordinatesXPosition +
						',' +
						coordinatesYPosition +
						']'
				)
			}

			if (event.target.elements.object_image_file.value) {
				formDataMissionContentAddOns.append(
					'object_image_file',
					event.target.elements.object_image_file.files[0]
				)
			}

			formDataMissionContentAddOns.append(
				'sub_world_scene',
				sceneSubWorld
			)
		}

		if (objectContentMission === undefined) {
			// SAVE
			manageContentMission(
				API_CONTENT_MISSION,
				formDataMissionContent,
				formDataMissionContentAddOns,
				'add'
			)
		} else {
			// UPDATE
			manageContentMission(
				API_CONTENT_MISSION +
					objectContentMission.mission_content.id_mission_content +
					'/',
				formDataMissionContent,
				formDataMissionContentAddOns,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE ANIMATION
	const manageContentMission = async (
		url,
		objMissionContent,
		objMissionContentAdd,
		action
	) => {
		try {
			const result_data = await axiosSupreme(
				action === 'add' ? 'post' : 'patch',
				url,
				objMissionContent
			)
			objMissionContentAdd.append(
				'mission_content',
				result_data.id_mission_content
			)
			if (objectContentMission === undefined) {
				// SAVE
				manageContentMissionAdd(
					API_CONTENT_MISSION_ADD_ONS,
					objMissionContentAdd,
					'add'
				)
			} else {
				// UPDATE
				manageContentMissionAdd(
					API_CONTENT_MISSION_ADD_ONS +
						objectContentMission.id_mission_content_add_ons +
						'/',
					objMissionContentAdd,
					'update'
				)
			}
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
	}

	const manageContentMissionAdd = async (
		url,
		objMissionContentAdd,
		action
	) => {
		try {
			await axiosSupreme(
				action === 'add' ? 'post' : 'patch',
				url,
				objMissionContentAdd
			)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshMissionsContent((prev) => !prev)
			if (action === 'update') getNewObject()
			setStateLoading(false)
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_CONTENT_MISSION_ADD_ONS +
				objectContentMission.id_mission_content_add_ons +
				'/',
			undefined
		)
		setStateObjectContentMission(result_data)
	}

	const handleKeyPress = (event) => {
		const charCode = event.charCode || event.keyCode
		if ((charCode < 48 || charCode > 57) && charCode !== 46) {
			event.preventDefault()
		}
	}

	// GET ALL STARS ANS LEVEL LANGUAGES
	const [listLevelLanguages, setListLevelLanguages] = useState({})
	const [listWorlds, setListWorlds] = useState({})
	const [listSubworlds, setListSubworlds] = useState({})
	const [listSceneSubWorlds, setListSceneSubWorlds] = useState({})

	const abortControllerLevelLanguage = useRef()
	const abortControllerChangeWorld = useRef()
	const abortControllerSubWorld = useRef()

	const { fetchOptions } = useFetchOptions()

	useEffect(() => {
		const abortController = new AbortController()

		if (typeMission === 'o') {
			let idGameLanguage = JSON.parse(getGameLanguage())

			const updateDataList = () => {
				setListLevelLanguages((prevData) => ({
					...prevData,
					...prevData.reduce((acc, dataItem) => {
						acc[dataItem.id_level_language] = {
							name: dataItem.name,
						}
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				`${API_MANAGE_LIST_LEVELS_LANGUAGES}${idGameLanguage.id_game_language}/`,
				updateDataList,
				abortController.signal
			)
		}

		return () => {
			abortController.abort()
		}
	}, [])

	const onChangeLevelLanguage = (value) => {
		setLevelLanguage(value)
		setWorld('')
		setSubWorld('')
		setSceneSubWorld('')
		setListWorlds({})
		setListSubworlds({})
		setListSceneSubWorlds({})
		if (value) {
			abortControllerLevelLanguage.current = new AbortController()

			if (abortControllerLevelLanguage.current instanceof AbortController)
				abortControllerLevelLanguage.current.abort()
			const updateDataList = (dataList) => {
				setListWorlds((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_world] = { name: dataItem.name }
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				`${GET_WORLDS_BY_LEVEL}${value}/`,
				updateDataList,
				abortControllerLevelLanguage.current.signal
			)
		}
	}

	const onChangeWorld = (value) => {
		setWorld(value)
		setSubWorld('')
		setSceneSubWorld('')
		setListSubworlds({})
		setListSceneSubWorlds({})
		if (value) {
			abortControllerChangeWorld.current = new AbortController()
			if (abortControllerChangeWorld.current instanceof AbortController)
				abortControllerChangeWorld.current.abort()

			const updateDataList = (dataList) => {
				setListSubworlds((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_sub_world] = { name: dataItem.name }
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				`${GET_SUB_WORLD_BY_WORLD}${value}/`,
				updateDataList,
				abortControllerChangeWorld.current.signal
			)
		}
	}

	const onChangeSubWorld = (value) => {
		setSubWorld(value)
		setSceneSubWorld('')
		setListSceneSubWorlds({})
		if (value) {
			if (abortControllerSubWorld.current instanceof AbortController)
				abortControllerSubWorld.current.abort()
			abortControllerSubWorld.current = new AbortController()

			const updateDataList = (dataList) => {
				setListSceneSubWorlds((prevData) => ({
					...prevData,
					...dataList.reduce((acc, dataItem) => {
						acc[dataItem.id_scene_sub_world] = {
							name: dataItem.name,
						}
						return acc
					}, {}),
				}))
			}

			fetchOptions(
				`${GET_SCENE_SUBWORLD_BY_SUBWORLD}${value}/`,
				updateDataList,
				abortControllerSubWorld.current.signal
			)
		}
	}

	return (
		<form onSubmit={onSubmitForm}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.order_title_and_placeholder'
					)}
				</label>
				<input
					maxLength='300'
					name='order'
					className='admin__container__inputs__in'
					id='order'
					type='number'
					placeholder={t(
						'pages.editor.components.form_content_missions.order_title_and_placeholder'
					)}
					autoComplete='off'
					ref={firstInputRef}
					value={order}
					onChange={(event) => onInputChangeDefault(event, setOrder)}
					onKeyPress={(event) => handleKeyPressInteger(event)}
					required></input>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.finnish_text'
					)}
				</label>
				<textarea
					maxLength='500'
					name='textTranslation'
					className='admin__container__inputs__in'
					id='text'
					type='text-area'
					placeholder={t(
						'pages.editor.components.form_content_missions.placesholder_description'
					)}
					autoComplete='off'
					value={text}
					onChange={(event) => onInputChangeDefault(event, setText)}
					required></textarea>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.spanish_text'
					)}
				</label>
				<textarea
					maxLength='500'
					name='textTranslation'
					className='admin__container__inputs__in'
					id='textTranslation'
					type='text-area'
					placeholder={t(
						'pages.editor.components.form_content_missions.placesholder_description'
					)}
					autoComplete='off'
					value={textTranslation}
					onChange={(event) =>
						onInputChangeDefault(event, setTextTranslation)
					}
					required></textarea>
			</div>
			{typeMission !== 'o' ? (
				<>
					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_content_missions.file_sound'
							)}
						</label>
						{objectContentMission !== undefined ? (
							<>
								{objectContentMission.audio_file !== null ? (
									<ManageFile
										pathFile={
											objectContentMission.audio_file
										}
										attribute='audio_file'
										url={
											API_CONTENT_MISSION_ADD_ONS +
											objectContentMission.id_mission_content_add_ons +
											'/'
										}
										getNewObject={getNewObject}
										setStateObject={
											setStateObjectContentMission
										}
									/>
								) : null}
							</>
						) : null}
						<input
							name='audio_file'
							className='admin__container__inputs__in'
							id='audio_file'
							type='file'
							ref={audioFile}
							autoComplete='off'
							onChange={(event) =>
								onInputChangeSound(
									event,
									setErrorAudio,
									audioFile
								)
							}
							accept='audio/*'></input>
						{errorAudio && <p>{errorAudio}</p>}
					</div>

					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_content_missions.image_reference'
							)}
						</label>
						{objectContentMission !== undefined ? (
							<>
								{objectContentMission.image_reference_file !==
								null ? (
									<ManageFile
										pathFile={
											objectContentMission.image_reference_file
										}
										attribute='image_reference_file'
										url={
											API_CONTENT_MISSION_ADD_ONS +
											objectContentMission.id_mission_content_add_ons +
											'/'
										}
										getNewObject={getNewObject}
										setStateObject={
											setStateObjectContentMission
										}
									/>
								) : null}
							</>
						) : null}
						<input
							name='image_reference_file'
							className='admin__container__inputs__in'
							id='image_reference_file'
							type='file'
							ref={ImageFile}
							autoComplete='off'
							onChange={(event) =>
								onInputChangeImage(
									event,
									setErrorImageFile,
									ImageFile
								)
							}
							accept='image/*'></input>
						{errorImageFile && <p>{errorImageFile}</p>}
					</div>
				</>
			) : null}
			{typeMission === 'o' ? (
				<>
					<br />
					<h3>
						{t(
							'pages.editor.components.form_content_missions.data_for_lost_object'
						)}
					</h3>

					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_content_missions.lang_level'
							)}
						</label>
						<select
							name='level_language'
							className='admin__container__inputs__in'
							id='level_language'
							placeholder={t(
								'pages.editor.components.form_content_missions.placeholder_stars'
							)}
							autoComplete='off'
							value={levelLanguage}
							onChange={(event) =>
								onChangeLevelLanguage(event.target.value)
							}
							required>
							<option key='' value=''>
								{t(
									'pages.editor.components.form_content_missions.select'
								)}
							</option>
							{Object.keys(listLevelLanguages).map((key) => (
								<option key={key} value={key}>
									{listLevelLanguages[key].name}
								</option>
							))}
						</select>
					</div>

					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_content_missions.world'
							)}
						</label>
						<select
							name='level_language'
							className='admin__container__inputs__in'
							id='level_language'
							placeholder={t(
								'pages.editor.components.form_content_missions.placeholder_stars'
							)}
							autoComplete='off'
							value={world}
							onChange={(event) =>
								onChangeWorld(event.target.value)
							}
							required>
							<option key='' value=''>
								{t(
									'pages.editor.components.form_content_missions.select'
								)}
							</option>
							{Object.keys(listWorlds).map((key) => (
								<option key={key} value={key}>
									{listWorlds[key].name}
								</option>
							))}
						</select>
					</div>

					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_content_missions.sub_world'
							)}
						</label>
						<select
							name='subworld'
							className='admin__container__inputs__in'
							id='subworld'
							placeholder={t(
								'pages.editor.components.form_content_missions.placeholder_stars'
							)}
							autoComplete='off'
							value={subWorld}
							onChange={(event) =>
								onChangeSubWorld(event.target.value)
							}
							required>
							<option key='' value=''>
								{t(
									'pages.editor.components.form_content_missions.select'
								)}
							</option>
							{Object.keys(listSubworlds).map((key) => (
								<option key={key} value={key}>
									{listSubworlds[key].name}
								</option>
							))}
						</select>
					</div>

					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_content_missions.sub_world_escene'
							)}
						</label>
						<select
							name='scene_subworld'
							className='admin__container__inputs__in'
							id='scene_subworld'
							placeholder={t(
								'pages.editor.components.form_content_missions.placeholder_stars'
							)}
							autoComplete='off'
							value={sceneSubWorld}
							onChange={(event) =>
								onInputChangeDefault(event, setSceneSubWorld)
							}
							required>
							<option key='' value=''>
								{t(
									'pages.editor.components.form_content_missions.select'
								)}
							</option>
							{Object.keys(listSceneSubWorlds).map((key) => (
								<option key={key} value={key}>
									{listSceneSubWorlds[key].name}
								</option>
							))}
						</select>
					</div>

					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_content_missions.object_image_file'
							)}
						</label>
						{objectContentMission !== undefined ? (
							<>
								{objectContentMission.object_image_file !==
								null ? (
									<label htmlFor=''>
										{t('common.current_file')}
										<a
											href={
												objectContentMission.object_image_file
											}
											target='_blank'
											rel='noopener noreferrer'>
											{t('common.open')}
										</a>
									</label>
								) : null}
							</>
						) : null}
						<input
							name='object_image_file'
							className='admin__container__inputs__in'
							id='object_image_file'
							type='file'
							ref={ObjectImageFile}
							autoComplete='off'
							onChange={(event) =>
								onInputChangeImage(
									event,
									setErrorObjectImageFile,
									ObjectImageFile
								)
							}
							accept='image/*'
							required={
								objectContentMission === undefined
							}></input>
						{errorObjectImageFile && <p>{errorObjectImageFile}</p>}
					</div>

					<div
						className='admin__container__inputsCoordenadas'
						id='admin__container__inputsCoordenadas'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_content_missions.frame_size'
							)}
						</label>
						<div className='admin__container__inputsCoordenadas__columns'>
							<input
								maxLength='45'
								name='frameSizeX'
								className='admin__container__inputsCoordenadas__columns__in'
								id='frameSizeX'
								type='text'
								placeholder={t(
									'pages.editor.components.form_content_missions.placeholder_width'
								)}
								autoComplete='off'
								value={frameSizeX}
								onChange={(event) =>
									onInputChangeDefault(event, setFrameSizeX)
								}
								onKeyPress={(event) => handleKeyPress(event)}
								required></input>
							<input
								maxLength='45'
								name='frameSizeY'
								className='admin__container__inputsCoordenadas__columns__in'
								id='frameSizeY'
								type='text'
								placeholder={t(
									'pages.editor.components.form_content_missions.placeholder_height'
								)}
								autoComplete='off'
								value={frameSizeY}
								onChange={(event) =>
									onInputChangeDefault(event, setFrameSizeY)
								}
								onKeyPress={(event) => handleKeyPress(event)}
								required></input>
						</div>
					</div>

					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t(
								'pages.editor.components.form_content_missions.amount_frames_and_placeholder'
							)}
						</label>
						<input
							maxLength='300'
							name='frames_amount'
							className='admin__container__inputs__in'
							id='frames_amount'
							type='number'
							placeholder={t(
								'pages.editor.components.form_content_missions.amount_frames_and_placeholder'
							)}
							autoComplete='off'
							value={framesAmount}
							onChange={(event) =>
								onInputChangeDefault(event, setFramesAmount)
							}
							onKeyPress={(event) => handleKeyPressInteger(event)}
							required></input>
					</div>

					<div
						className='admin__container__inputsCoordenadas'
						id='admin__container__inputsCoordenadas'>
						<label
							className='admin__container__inputsCoordenadas__title'
							forhtml='admin__container__inputsCoordenadas__title'>
							{t(
								'pages.editor.components.form_content_missions.coordinates'
							)}
						</label>
						<div className='admin__container__inputsCoordenadas__columns'>
							<input
								maxLength='45'
								name='coordinatesXPosition'
								className='admin__container__inputsCoordenadas__columns__in'
								id='coordinatesXPosition'
								type='text'
								placeholder={t(
									'pages.editor.components.form_content_missions.x_position'
								)}
								autoComplete='off'
								value={coordinatesXPosition}
								onChange={(event) =>
									onInputChangeDefault(
										event,
										setCoordinatesXPosition
									)
								}
								onKeyPress={(event) => handleKeyPress(event)}
								required></input>
							<input
								maxLength='45'
								name='coordinatesYPosition'
								className='admin__container__inputsCoordenadas__columns__in'
								id='coordinatesYPosition'
								type='text'
								placeholder={t(
									'pages.editor.components.form_content_missions.y_position'
								)}
								autoComplete='off'
								value={coordinatesYPosition}
								onChange={(event) =>
									onInputChangeDefault(
										event,
										setCoordinatesYPosition
									)
								}
								onKeyPress={(event) => handleKeyPress(event)}
								required></input>
						</div>
					</div>
				</>
			) : null}
			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

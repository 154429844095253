import React, { useContext, useEffect, useState } from 'react'
import SaveButton from '../../../SaveButton'
import AlertContext from '../../../../../context/editor/AlertContext'
import ModalContext from '../../../../../context/editor/ModalContext'
import { API_MANAGE_NPC_STATES } from '../../../../../utils/constantsAdmin'
import {
	onInputChangeDefault,
	processErrors,
} from '../../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormStates = ({
	stateObjectState,
	setStateObjectState,
	setRefreshStatesNpc,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setDescription('')
	}

	// SET LEVEL WHEN UPDATE
	useEffect(() => {
		if (stateObjectState !== undefined) {
			setName(stateObjectState.name)
			setDescription(stateObjectState.description)
		} else {
			setName('')
			setDescription('')
		}
	}, [stateObjectState])

	// SUBMIT FORM STATES
	const onSubmitStates = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		formData.append('description', event.target.elements.description.value)

		if (stateObjectState === undefined) {
			// SAVE
			manageStates(event, API_MANAGE_NPC_STATES, formData, 'add')
		} else {
			// UPDATE
			manageStates(
				event,
				API_MANAGE_NPC_STATES + stateObjectState.id_state_npc + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE STATES
	const manageStates = async (event, url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshStatesNpc((prev) => !prev)
			if (action === 'update') setStateObjectState(result_data)
			openModal(false)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	return (
		<>
			<form onSubmit={onSubmitStates}>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_states.name_state')}
					</label>
					<input
						maxLength='45'
						name='name'
						className='admin__container__inputs__in'
						id='name'
						type='text'
						placeholder={t(
							'pages.editor.components.form_states.name_state_placeholder'
						)}
						autoComplete='off'
						value={name}
						onChange={(event) =>
							onInputChangeDefault(event, setName)
						}
						disabled={
							stateObjectState &&
							(stateObjectState.name === 'idle' ||
								stateObjectState.name === 'greeting')
						}
						required></input>
				</div>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_states.description_and_placeholder'
						)}
					</label>
					<textarea
						maxLength='500'
						name='description'
						className='admin__container__inputs__in'
						id='description'
						type='text-area'
						placeholder={t(
							'pages.editor.components.form_states.description_and_placeholder'
						)}
						autoComplete='off'
						value={description}
						onChange={(event) =>
							onInputChangeDefault(event, setDescription)
						}></textarea>
				</div>
				{stateLoading === false ? (
					<div className='admin__container__boxBtn'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</form>
		</>
	)
}

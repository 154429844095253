import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../../context/editor/ModalContext'
import { FormStates } from './FormStates'
import { API_MANAGE_NPC_STATES } from '../../../../../utils/constantsAdmin'
import { SearchCamp } from '../../../searchAndPagination/SearchCamp'
import { NpcStatesContent } from './NpcStatesContent'
import { Pagination } from '../../../searchAndPagination/Pagination'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListNpcState = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// REFRESH STATES
	const [refreshStatesNpc, setRefreshStatesNpc] = useState(false)

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	const OpenAddStates = () => {
		setTitleModal(t('pages.editor.components.list_npc_state.add_state'))
		setContentModal(
			<FormStates setRefreshStatesNpc={setRefreshStatesNpc} />
		)
		openModal(true)
	}

	// GET ALL STATES
	const [dataStates, setDataStates] = useState(undefined)
	useEffect(() => {
		getListStates('start')
	}, [refreshStatesNpc])

	const getListStates = async (loading_state) => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_NPC_STATES,
			undefined
		)
		setDataStates(result_data)

		if (loading_state === 'start') {
			const default_values = {
				idle: { description: 'Normal State' },
				greeting: { description: 'Greeting State' },
			}
			result_data.results.forEach((data_item) => {
				if (default_values[data_item.name] !== undefined) {
					delete default_values[data_item.name]
				}
			})
			addDefaultStates(default_values)
		}
	}

	const addDefaultStates = async (data) => {
		Object.keys(data).map(async (key) => {
			let formData = new FormData()
			formData.append('name', key)
			formData.append('description', data[key].description)
			await axiosSupreme('post', API_MANAGE_NPC_STATES, formData)
			return null
		})
		getListStates('post-start')
	}

	return (
		<div className='nav-content'>
			<div className='npcDataContent__btnAdd'>
				<h1>{t('pages.editor.components.list_npc_state.states')}</h1>
				<div className='boxBtnAdd'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddStates}>
						{t('common.add')}
					</button>
				</div>
			</div>
			{dataStates !== undefined ? (
				<>
					{dataStates.results.length > 0 ||
					dataStates === 'search' ? (
						<>
							<SearchCamp
								setData={setDataStates}
								setRefreshDataList={setRefreshStatesNpc}
								url={API_MANAGE_NPC_STATES}
								search={search}
								setSearch={setSearch}
							/>
							{dataStates === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_npc_state.none_state_found'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t(
								'pages.editor.components.list_npc_state.none_state_loaded'
							)}
						</p>
					)}
					{dataStates !== 'search' ? (
						<>
							{dataStates.results.map((data_item, index) => (
								<NpcStatesContent
									key={data_item.id_state_npc}
									id_state_npc={data_item.id_state_npc}
									objectStates={data_item}
									setRefreshStatesNpc={setRefreshStatesNpc}
								/>
							))}
							<Pagination
								data={dataStates}
								setData={setDataStates}></Pagination>
						</>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}

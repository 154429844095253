import { useEffect, useRef, useState } from 'react'
import '../../styles/Config.scss'

import IconButton from '../shared/IconButton/IconButton.js'

import backIcon from '../../assets/icons/back.png'
import { useSceneConfig } from '../../context/SceneConfigProvider.jsx'
// import Shuffle from '../fragments/Shuffle.jsx'
import { GAME_FRONTEND } from '../../urls.js'
import { useTutorial } from '../../hooks/useTutorial.js'
import { useSoundAction } from '../../hooks/useSoundAction.js'
import { useUpdateProfileContext } from '../SceneDataFetch.jsx'
import volumePng from '../../assets/icons/volumeOn.png'
import volumeMute from '../../assets/icons/volumeMute.png'
import { t } from 'i18next'
import i18n from '../../i18n.js'
import { useTranslation } from 'react-i18next'

const Config = ({ onClose }) => {
	/**
	 * Bueno, cada uno de esos botones está ligado a un contenedor que muestra x configuración.
	 *
	 * Asi que cada bloque, tiene un nombre, un onClick, y un Componente
	 */
	const { tutorialSecuence } = useTutorial()
	const [profileData, , sceneData, goToNextScene] = useUpdateProfileContext()
	const [configData] = useState([
		{
			name: t('pages.game.components.configuration_panel.btns.sound_btn'),
			component: Sound,
		},
		{
			name: t('pages.game.components.configuration_panel.btns.languages'),
			component: Languages,
		},
		{
			name: t(
				'pages.game.components.configuration_panel.btns.how_to_play_btn'
			),
			component: Tutorial,
			onClick: (e) => {
				tutorialSecuence.open()
				soundAction('effect', 'TLSG6', 'play')
				onClose(e)
			},
		},
		{
			name: t('pages.game.components.configuration_panel.btns.start_btn'),
			component: <></>,
			onClick: (e) => {
				window.location.href = GAME_FRONTEND
				soundAction('effect', 'TLSG6', 'play')
				onClose(e)
			},
		},
		{
			name: t('pages.game.components.configuration_panel.btns.lobby_btn'),
			component: <></>,
			onClick: (e) => {
				goToNextScene(
					sceneData.levelEntranceDoorID, //'76b3f783-07a7-4027-8970-ff4cf84755ff',
					profileData.id_profile,
					'LEVEL ENTRANCE DOOR',
					true, // goes to world
					true
				)
				soundAction('effect', 'TLSG6', 'play')
				onClose(e)
			},
		},
		{
			name: t(
				'pages.game.components.configuration_panel.btns.delete_account_btn'
			),
			component: DeleteAccount,
		},
	])

	const [sectionIsActive, setSectionIsActive] = useState(false)
	const [activeSectionIndex, setActiveSectionIndex] = useState(0)
	const ActiveSectionComponent = configData[activeSectionIndex].component

	const { soundAction } = useSoundAction()

	const onClick = (index) => {
		setSectionIsActive(true)
		setActiveSectionIndex(index)
	}

	return (
		<div className='config'>
			{sectionIsActive && (
				<BackButton
					setSectionIsActive={setSectionIsActive}
					soundAction={soundAction}
				/>
			)}

			{!sectionIsActive ? (
				<div className='config__main'>
					{configData.map((section, sectionIndex) => {
						if (
							section.name ===
								t(
									'pages.game.components.configuration_panel.btns.lobby_btn'
								) &&
							!sceneData.subWorld
						)
							return null

						return (
							<button
								key={sectionIndex}
								className='config__main__button'
								onClick={
									section.onClick
										? (e) => {
												section.onClick(e)
										  }
										: () => {
												soundAction(
													'effect',
													'TLSG3',
													'play'
												)
												onClick(sectionIndex)
										  }
								}>
								{section.name}
							</button>
						)
					})}
				</div>
			) : (
				<ActiveSectionComponent />
			)}
		</div>
	)
}

const Sound = () => {
	/**
	 * La configuración del audio, debe mantenerse durante cada
	 * cambio de escena, por ende no debe tener los datos guardados
	 * en la escena, sino más bien en una parte de la herarquina más grange.
	 */
	const { soundData, setSoundData } = useSceneConfig()
	const { soundAction } = useSoundAction()

	return (
		<div className='config__sound'>
			{soundData.map((config, configIndex) => (
				<div className='config__sound__section' key={configIndex}>
					<span className='config__sound__section__text'>
						{config.name}
					</span>
					<ControlVolume nameSound={config.name} />
					{/* <Shuffle
						isActive={config.isActive}
						onClick={() => {
							soundAction('effect', 'TLSG3', 'play')
							setSoundData((sd) => {
								let sdCopy = structuredClone(sd)
								sdCopy[configIndex].isActive =
									!sdCopy[configIndex].isActive
								return sdCopy
							})
						}}
					/> */}
				</div>
			))}
		</div>
	)
}

const Languages = () => {
	const { t } = useTranslation()
	const [langs] = useState([
		{ key: 'es', lang: t('languages.es') },
		{ key: 'en', lang: t('languages.en') },
		{ key: 'fi', lang: t('languages.fi') },
		{ key: 'de', lang: t('languages.de') },
		{ key: 'sv', lang: t('languages.sv') },
	])

	const handleClick = (lang) => {
		localStorage.setItem('lang', lang)
		i18n.changeLanguage(lang)
	}

	return (
		<div className='config__sound'>
			{langs.map((langItem, index) => (
				<div className='config__sound__section' key={index}>
					<button
						className='config__sound__btn'
						onClick={() => {
							handleClick(langItem.key)
						}}>
						{langItem.lang}
					</button>
				</div>
			))}
		</div>
	)
}

function ControlVolume({ nameSound }) {
	const { soundData, setSoundData } = useSceneConfig()
	const { changeVolumeOfAudios } = useSoundAction()
	const [volume, setVolume] = useState(1)
	const inputRef = useRef()

	useEffect(() => {
		let bgSound = 100
		const volume = soundData.find((sd) => sd.name === nameSound)?.volume

		if (!Number.isNaN(Number(volume))) {
			bgSound = Number(volume) * 100
		}

		inputRef.current.style.background = `linear-gradient(90deg, #490d08 0px ${bgSound}px, #959595 ${bgSound}px 100px)`
		setVolume(bgSound / 100)
	}, [])

	const changeVolume = (e) => {
		const { value } = e.target
		setVolume(value)

		inputRef.current.style.background = `linear-gradient(90deg, #490d08 0px ${
			value * 100
		}px, #959595 ${value * 100}px 100px)`
		setSoundData((sd) => {
			const sounds = structuredClone(sd)
			sounds.find((s, i) => {
				if (s.name === nameSound) {
					sounds[i].volume = value
					return true
				}

				return false
			})
			return sounds
		})
		changeVolumeOfAudios({ nameSound, volume: value })
	}

	return (
		<div className='controlVolume'>
			<picture>
				<img
					src={Number(volume) === 0 ? volumeMute : volumePng}
					alt='volumen'
					decoding='async'
				/>
			</picture>
			<label>
				<input
					type='range'
					min={0}
					max={1}
					step={0.1}
					value={volume}
					onChange={changeVolume}
					ref={inputRef}
				/>
				<span>{volume * 100}%</span>
			</label>
		</div>
	)
}

const Tutorial = () => {
	return <></>
}

const BackButton = ({ setSectionIsActive, soundAction }) => {
	return (
		<IconButton
			src={backIcon}
			size={'28px'}
			onClick={() => {
				soundAction('effect', 'TLSG3', 'play')
				setSectionIsActive(false)
			}}
			parentStyle={{ cursor: 'pointer', marginBottom: '1rem' }}
		/>
	)
}

function DeleteAccount() {
	return (
		<div className='config_delete_account'>
			<p className='config_delete_account_text'>
				{t(
					'pages.game.components.configuration_panel.components_pages.delete_account.text'
				)}
			</p>

			<a
				className='config_delete_account_button'
				href='https://teoloro.com/privacy-polices'
				target='_blank'
				rel='noreferrer noopener'>
				{t(
					'pages.game.components.configuration_panel.components_pages.delete_account.btn'
				)}
			</a>
		</div>
	)
}

export default Config

/**
 * What is that?
 *
 * It is a blackscreen, the entire screen.
 */

import { useContext, useEffect, useState } from 'react'
import { API_MANAGE_GAMES } from '../../../../../utils/constantsAdmin'
import ModalContext from '../../../../../context/editor/ModalContext'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { GameBodyCollapse } from './GameBodyCollapse'
import { FormGame } from './FormGame'
import { toggleCollapse } from '../../../../../utils/general_functions'
import AlertContext from '../../../../../context/editor/AlertContext'
import DeleteContext from '../../../../../context/editor/DeleteContext'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const GameCollapse = ({
	objectGame,
	setRefreshGames,
	activeCollapse,
	setActiveCollapse,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// SET OBJECT GAME
	const [stateObjectGame, setStateObjectGame] = useState(undefined)
	useEffect(() => {
		setStateObjectGame(objectGame)
	}, [objectGame])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE GAME
	const UpdateGame = async () => {
		setTitleModal(t('pages.editor.components.game_collapse.title'))
		setContentModal(
			<FormGame
				stateObjectGame={stateObjectGame}
				setStateObjectGame={setStateObjectGame}
				setRefreshGames={setRefreshGames}
			/>
		)
		openModal(true)
	}

	const DeleteGame = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_GAMES + stateObjectGame.id_game + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(t('common.cannot_delete'))
			showAlert()
		} else {
			setRefreshGames((prev) => !prev)
			openDelete()
		}
	}
	return (
		<div className='adminGameComponents'>
			{stateObjectGame !== undefined ? (
				<>
					<div className='adminGameComponents__button collapseStylesBtn'>
						{activeCollapse === stateObjectGame.id_game ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='adminGameComponents__button__title title'
							onClick={() =>
								toggleCollapse(
									activeCollapse,
									setActiveCollapse,
									stateObjectGame.id_game
								)
							}>
							<h2>{stateObjectGame.name}</h2>
						</div>
						<div className='adminGameComponents__button__options options'>
							<button
								className='editorBtnActionDelete'
								onClick={() => {
									setFunctionDelete(() => DeleteGame)
									setMessage(
										t(
											'pages.editor.components.game_collapse.confirm_deleting_1'
										) +
											stateObjectGame.name +
											'?'
									)
									openDelete()
								}}>
								<p>
									<BsFillTrashFill />{' '}
								</p>
							</button>
							<button
								className='editorBtnActionEdit'
								onClick={UpdateGame}>
								<p>
									<BsFillPencilFill />
								</p>
							</button>
						</div>
					</div>
					{activeCollapse === stateObjectGame.id_game && (
						<GameBodyCollapse
							stateObjectGame={
								stateObjectGame
							}></GameBodyCollapse>
					)}
				</>
			) : null}
		</div>
	)
}

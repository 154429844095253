import { useTranslation } from 'react-i18next'
import { ListStoryTellerContent } from '../StoryTellerContent/ListStoryTellerContent'

export const BodyCollapseStoryTeller = ({ stateObjectStoryTeller }) => {
	const { t } = useTranslation()

	return (
		<div className='storyTellerContent collapseStylesBody'>
			{stateObjectStoryTeller !== undefined ? (
				<>
					<div className='storyTellerContent__container'>
						<div className='storyTellerContent__container__data'>
							<div className='info'>
								<h1>
									{t(
										'pages.editor.components.body_collapse_storyteller.title'
									)}
								</h1>
								<p>{stateObjectStoryTeller.description}</p>
							</div>
						</div>
						<ListStoryTellerContent
							stateObjectStoryTeller={stateObjectStoryTeller}
						/>
					</div>
				</>
			) : null}
		</div>
	)
}

import React, { useContext, useEffect, useState } from 'react'
import '../../../../styles/ObjectsAdmin.scss'
import { BsFillTrashFill, BsFillPencilFill } from 'react-icons/bs'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_MANAGE_DECORATIONS,
	API_MANAGE_DECORATIONS_SUB_WORLD,
} from '../../../../utils/constantsAdmin'
import { FormDecorationScene } from './FormDecorationScene'
import { BodyCollapseDecorations } from './BodyCollapseDecorations'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { toggleCollapse } from '../../../../utils/general_functions'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const CollapseDecorations = ({
	idScene,
	objectDecoration,
	setRefreshDecorations,
	activeCollapseDecorations,
	setActiveCollapseDecorations,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// SET OBJECT ITEM
	const [idDecoration, setIdDecoration] = useState(undefined)
	const [stateObjectDecoration, setStateObjectDecoration] =
		useState(undefined)
	useEffect(() => {
		setStateObjectDecoration(objectDecoration)
		setIdDecoration(
			gettypeContextSpace() === 'World'
				? objectDecoration.id_world_scene_decoration
				: objectDecoration.id_sub_world_scene_decoration
		)
	}, [objectDecoration])

	// LOCAL STORAGE
	const { gettypeContextSpace } = ManageStorageAdminDesigner()

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE DECORATION
	const UpdateDecoration = async () => {
		setTitleModal(t('pages.editor.components.collapse_decorations.title'))
		setContentModal(
			<FormDecorationScene
				idScene={idScene}
				stateObjectDecoration={stateObjectDecoration}
				setStateObjectDecoration={setStateObjectDecoration}
				setRefreshDecorations={setRefreshDecorations}
			/>
		)
		openModal(true)
	}

	// DELETE DECORATION
	const DeleteDecoration = async () => {
		const urlRequest =
			gettypeContextSpace() === 'World'
				? API_MANAGE_DECORATIONS +
				  stateObjectDecoration.id_world_scene_decoration +
				  '/'
				: API_MANAGE_DECORATIONS_SUB_WORLD +
				  stateObjectDecoration.id_sub_world_scene_decoration +
				  '/'
		const result_data = await axiosSupreme('delete', urlRequest, undefined)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(t('common.cannot_delete'))
			showAlert()
		} else {
			setRefreshDecorations((prev) => !prev)
			openDelete()
		}
	}

	return (
		<div className='adminComponents'>
			{stateObjectDecoration !== undefined ? (
				<>
					<div className='adminComponents__button collapseStylesBtn'>
						{activeCollapseDecorations === idDecoration ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='adminComponents__button__title title'
							onClick={() =>
								toggleCollapse(
									activeCollapseDecorations,
									setActiveCollapseDecorations,
									gettypeContextSpace() === 'World'
										? stateObjectDecoration.id_world_scene_decoration
										: stateObjectDecoration.id_sub_world_scene_decoration
								)
							}>
							<h2>{stateObjectDecoration.animation.name}</h2>
						</div>
						<div className='adminComponents__button__options options'>
							<button
								className='editorBtnActionDelete'
								onClick={() => {
									setFunctionDelete(() => DeleteDecoration)
									setMessage(
										t(
											'pages.editor.components.collapse_decorations.confirm_deleting_1'
										) +
											stateObjectDecoration.animation
												.name +
											t(
												'pages.editor.components.collapse_decorations.confirm_deleting_2'
											)
									)
									openDelete()
								}}>
								<p>
									<BsFillTrashFill />
								</p>
							</button>
							<button
								className='editorBtnActionEdit'
								onClick={UpdateDecoration}>
								<p>
									<BsFillPencilFill />
								</p>
							</button>
						</div>
					</div>
					{activeCollapseDecorations === idDecoration && (
						<BodyCollapseDecorations
							stateObjectDecoration={
								stateObjectDecoration
							}></BodyCollapseDecorations>
					)}
				</>
			) : null}
		</div>
	)
}
